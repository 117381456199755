<script>
import BnInfo from '@/components/BnInfo.vue';
import BnToolbarBtn from '@/components/BnToolbarBtn.vue';
import BnRichTextEditor from '@/components/questionnaires/form/BnRichTextEditor.vue';
import BnRichTextToolbar from '@/components/questionnaires/form/BnRichTextToolbar.vue';

export default {
    name: 'BnDialog',
    components: {
        BnInfo,
        BnToolbarBtn,
        BnRichTextToolbar,
        BnRichTextEditor,
    },
    props: {},
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            options: null,
            // the following options can be set via: open(options)
            baseOptions: {
                type: 'confirm',
                header: 'Confirm',
                cancel: 'Cancel',
                cancelIcon: 'times',
                secondaryIcon: 'lock-alt',
                confirm: 'Confirm',
                confirmIcon: 'check',
                width: 500,
                persistent: true,
                info: undefined,
                warning: undefined,
                error: undefined,
                invalid: '',
            },
            response: undefined,
            other: undefined,
            activeRichTextEditor: null,
            activeRichTextToolbar: undefined,
        };
    },
    computed: {
        disableSave() {
            return !!this.options.invalid;
        },
        hasDefaultSlot() {
            return !!this.$slots.default;
        },
    },
    methods: {
        show(options) {
            this.options = { ...this.baseOptions, ...options };
            if (this.options.info && !Array.isArray(this.options.info)) {
                this.options.info = [this.options.info];
            }
            if (this.options.warning && !Array.isArray(this.options.warning)) {
                this.options.warning = [this.options.warning];
            }
            if (this.options.error && !Array.isArray(this.options.error)) {
                this.options.error = [this.options.error];
            }
            if (this.options.value) this.response = this.options.value;
            this.dialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        hide() {
            this.dialog = false;
        },
        confirm() {
            this.validate();
            let response;
            if (this.options.type === 'radio' && this.options.returnObject) {
                response = this.options.options.find((item) => item.value === this.response);
                if (this.other) {
                    response.text = this.other;
                }
                response = response || true;
            } else {
                response = this.response || true;
            }
            this.close(response);
        },
        close(response) {
            this.cancel(response);
        },
        cancel(resolve) {
            this.$nextTick(() => {
                this.resolve(resolve);
                this.dialog = false;
                this.response = undefined;
                this.other = undefined;
                this.options = undefined;
            });
        },
        setRTE(args) {
            this.activeRichTextEditor = args.editor;
            this.activeRichTextToolbar = args.toolbar;
        },
        closeEventHandler(e) {
            if (e.key !== 'Escape' && !e.pointerId) {
                return;
            }
            if (!this.options.persistent) {
                this.cancel();
            }
        },
        validate(value) {
            if (this.options.validate) {
                this.$emit('validate', !!value);
            }
        },
        submitOnEnter(e) {
            if (e.key === 'Enter') {
                this.$nextTick(() => {
                    if (!this.disableSave) {
                        this.confirm();
                    }
                });
            }
        },
        secondaryAction() {
            return this.close('secondary');
        },
    },
};
</script>

<template>
    <v-dialog
        v-model="dialog"
        :width="options?.width"
        scrollable
        :persistent="options?.persistent"
        :fullscreen="options?.fullscreen && $vuetify.display.xs"
        @click:outside="closeEventHandler"
        @keydown="closeEventHandler"
    >
        <!-- Dialog - use the slot to pass in data -->
        <template v-if="hasDefaultSlot">
            <!-- use the slot contents only -->
            <slot v-if="!options?.header"></slot>
            <!-- add header/toolbar to top of slotted content -->
            <v-card v-else class="d-flex flex-column">
                <div class="flex-grow-0">
                    <ion-header>
                        <ion-toolbar>
                            <v-toolbar density="compact">
                                <v-toolbar-title>{{ options?.header }}</v-toolbar-title>
                                <BnToolbarBtn v-if="options.cancel" type="text" :label="options.cancel" :icon="options.cancelIcon" data-cy="bnDialogCancelButton" @click="cancel(false)" />
                                <BnToolbarBtn
                                    v-if="options.confirm"
                                    :disabled="options.disabled"
                                    type="outlined"
                                    :label="options.confirm"
                                    :icon="options.confirmIcon"
                                    color="primary"
                                    right
                                    data-cy="bnDialogConfirmButton"
                                    @click="confirm"
                                />
                                <BnToolbarBtn v-else type="icon" icon="times" right @click="cancel(false)" />
                            </v-toolbar>
                        </ion-toolbar>
                    </ion-header>
                    <v-divider></v-divider>
                    <!-- Info/Warning message -->
                    <div v-if="options.info || options.warning || options.error">
                        <BnInfo v-for="(info, index) of options.info" :key="'info-' + index" type="outlined" :message="info" />
                        <BnInfo v-for="(warning, index) of options.warning" :key="'warning-' + index" type="outlined" color="warning" :message="warning" />
                        <BnInfo v-for="(error, index) of options.error" :key="'error-' + index" type="outlined" color="error" :message="error" />
                    </div>
                </div>
                <v-card class="scroll-on" :border="false">
                    <slot></slot>
                </v-card>
            </v-card>
        </template>

        <!-- Prompt -->
        <v-card v-else-if="dialog" class="d-flex flex-column" data-cy="bnDialog">
            <div class="flex-grow-0">
                <v-toolbar v-if="!options.bottomButtons" density="compact">
                    <v-toolbar-title>{{ options.header }}</v-toolbar-title>
                    <!-- Use toolbar in the header -->
                    <BnToolbarBtn
                        v-if="options.cancel && !options.bottomButtons"
                        type="text"
                        :label="options.cancel"
                        :icon="options.cancelIcon"
                        data-cy="bnDialogCancelButton"
                        @click="cancel(false)"
                    />
                    <BnToolbarBtn
                        v-if="options.secondary && !options.bottomButtons"
                        type="text"
                        :label="options.secondary"
                        :icon="options.secondaryIcon"
                        data-cy="bnDialogCancelButton"
                        @click="secondaryAction()"
                    />
                    <BnToolbarBtn
                        v-if="options.confirm && !options.bottomButtons"
                        type="outlined"
                        :disabled="disableSave"
                        :label="options.confirm"
                        :icon="options.confirmIcon"
                        color="primary"
                        data-cy="bnDialogConfirmButton"
                        @click="confirm"
                    />
                </v-toolbar>
                <v-divider></v-divider>
                <div v-if="options.type === 'textbox'">
                    <BnRichTextToolbar :editor="activeRichTextEditor" :toolbar="activeRichTextToolbar" />
                </div>

                <!-- Info/Warning message -->
                <div v-if="options.info || options.warning || options.error" :class="{ 'mb-4': !options.message }">
                    <BnInfo v-for="(info, index) of options.info" :key="'info-' + index" type="outlined" :message="info" />
                    <BnInfo v-for="(warning, index) of options.warning" :key="'warning-' + index" type="outlined" color="warning" icon="warning" :message="warning" />
                    <BnInfo v-for="(error, index) of options.error" :key="'error-' + index" type="outlined" color="error" icon="error" :message="error" />
                </div>
                <!-- Message/dialog content -->
                <div v-if="options.message">
                    <v-card-text class="pa-4 pt-2" :class="{ 'text-center': options.centerMessage }" data-cy="bnDialogText">
                        <div class="text-body-1" v-html="options.message"></div>
                    </v-card-text>
                </div>
            </div>

            <v-card v-if="options.type" class="scroll-on" :border="false">
                <!-- Capture Rich Text TODO: restrict height-->
                <div v-if="options.type === 'textbox'" class="d-flex pt-2">
                    <BnRichTextEditor v-model="response" autofocus class="mx-4" style="width: 100%" data-cy="bnDialogRTEField" @set-r-t-e="setRTE" />
                </div>

                <!-- Capture Plain Text -->
                <div v-else-if="options.type === 'text'" class="d-flex">
                    <v-text-field
                        v-model="response"
                        :label="options.label"
                        autofocus
                        :error-messages="options.invalid"
                        class="ma-4"
                        data-cy="bnDialogTextInput"
                        @update:model-value="validate"
                        @blur="validate"
                        @keypress="submitOnEnter"
                    />
                </div>

                <!-- Radio Options -->
                <div v-else-if="options.type === 'radio'">
                    <v-radio-group v-model="response" class="mx-4 my-2">
                        <v-radio v-for="(option, index) of options.options" :key="'option' + index" :label="option.text" :value="option.value" data-cy="bnDialogRadio" />
                    </v-radio-group>
                    <v-text-field v-if="response === 'other'" v-model="other" label="Please specify:" autofocus class="mx-4 mt-n4"></v-text-field>
                </div>

                <!-- Checkboxes -->
                <div v-else-if="options.type === 'checkbox'" class="ma-4 mt-0">
                    {{ options.response }}
                    <v-checkbox
                        v-for="(option, index) of options.options"
                        :key="'option' + index"
                        v-model="response"
                        :label="option.text"
                        :value="option.value"
                        hide-details
                        data-cy="bnDialogCheckbox"
                    />
                </div>
                <v-card-actions v-if="options.bottomButtons" class="justify-space-around" :class="{ ' mx-6': $vuetify.display.smAndUp }">
                    <BnToolbarBtn v-if="options.cancel" type="text" :label="options.cancel" :icon="options.cancelIcon" data-cy="bnDialogCancelButton" @click="cancel(false)" />
                    <BnToolbarBtn v-if="options.secondary" type="text" :label="options.secondary" :icon="options.secondaryIcon" data-cy="bnDialogSecondaryButton" @click="secondaryAction()" />
                    <BnToolbarBtn
                        v-if="options.confirm"
                        force-label
                        type="outlined"
                        :disabled="disableSave"
                        :label="options.confirm"
                        :icon="options.confirmIcon"
                        color="primary"
                        data-cy="bnDialogConfirmButton"
                        @click="confirm"
                /></v-card-actions>
            </v-card>
        </v-card>
    </v-dialog>
</template>
