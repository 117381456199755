<script>
import { administrativeGender, religiousAffiliation, race, ethnicityDetail, pronouns, languages, genderIdentity, maritalStatus, sexualOrientation } from '@/common/api/terminology.api';
import { Address, createFhirResource, ContactPoint, PatientCommunication, traits, types as FHIRWorksTypes } from '@/fhirworks';

import dataProvider from '@/components/DataProvider.js';
import BnDatePicker from '@/components/BnDatePicker.vue';

import { isPatient, sortByList } from '@/common/core.js';

import timezoneApi from '@/common/api/timezone.api.js';
import { getGoogleTimeZoneInfo } from '@/common/api/googleTimeZone.api.js';

import { minLength, required, requiredIf } from '@vuelidate/validators';
import { usePatientAccess } from '@/composables/usePatientAccess';
import debounce from 'lodash/debounce';
import merge from 'lodash/merge';
import { $httpTerminology } from '@/common/api/terminology.api.js';
import BnImageUploader from '@/components/BnImageUploader.vue';
import BnListBox from '@/components/BnListBox.vue';
import BnDialog from '@/components/BnDialog.vue';
import BnContactPointEdit from '@/components/contacts/BnContactPointEdit.vue';
import BnContactAddress from '@/components/contacts/BnContactAddress.vue';
import BnListQualifications from '@/components/lists/BnListQualifications.vue';
import BnListIdentifiers from '@/components/lists/BnListIdentifiers.vue';
import BnInfo from '@/components/BnInfo.vue';
import BnRelated from '@/components/contacts/BnRelated.vue';
import { useVuelidate } from '@vuelidate/core';
import BnTooltip from '@/components/BnTooltip';
import { isUser } from '@/common/core';

const raceCategoryLevel1Codes = ['1002-5', '2028-9', '2054-5', '2076-8', '2106-3', '2131-1'];
const raceCategoryCodeSystem = 'urn:oid:2.16.840.1.113883.6.238';
const ethnicityCategoryCodeSystem = 'urn:oid:2.16.840.1.113883.6.238';
const raceCategoryData = [
    new FHIRWorksTypes.Coding({
        code: '1002-5',
        system: raceCategoryCodeSystem,
        display: 'American Indian or Alaska Native',
    }),
    new FHIRWorksTypes.Coding({
        code: '2028-9',
        system: raceCategoryCodeSystem,
        display: 'Asian',
    }),
    new FHIRWorksTypes.Coding({
        code: '2054-5',
        system: raceCategoryCodeSystem,
        display: 'Black or African American',
    }),
    new FHIRWorksTypes.Coding({
        code: '2076-8',
        system: raceCategoryCodeSystem,
        display: 'Native Hawaiian or Other Pacific Islander',
    }),
    new FHIRWorksTypes.Coding({
        code: '2106-3',
        system: raceCategoryCodeSystem,
        display: 'White',
    }),
    new FHIRWorksTypes.Coding({
        code: '2131-1',
        system: raceCategoryCodeSystem,
        display: 'Other Race',
    }),
];

const ethnicityCategoryHispanicLatinoCode = '2135-2';
const ethnicityCategoryNotHispanicLatinoCode = '2186-5';
const ethnicityCategoryData = [
    new FHIRWorksTypes.Coding({
        code: ethnicityCategoryHispanicLatinoCode,
        system: ethnicityCategoryCodeSystem,
        display: 'Hispanic or Latino',
    }),
    new FHIRWorksTypes.Coding({
        code: ethnicityCategoryNotHispanicLatinoCode,
        system: ethnicityCategoryCodeSystem,
        display: 'Not Hispanic or Latino',
    }),
];

const livingArrangementData = [
    new FHIRWorksTypes.CodeableConcept({
        coding: [{ system: 'http://terminology.hl7.org/CodeSystem/v3-LivingArrangement', code: 'CS', display: 'Community shelter' }],
    }),
    new FHIRWorksTypes.CodeableConcept({
        coding: [{ system: 'http://terminology.hl7.org/CodeSystem/v3-LivingArrangement', code: 'HL', display: 'Homeless' }],
    }),
    new FHIRWorksTypes.CodeableConcept({
        coding: [{ system: 'http://terminology.hl7.org/CodeSystem/v3-LivingArrangement', code: 'M', display: 'Nomadic' }],
    }),
    new FHIRWorksTypes.CodeableConcept({
        coding: [{ system: 'http://terminology.hl7.org/CodeSystem/v3-LivingArrangement', code: 'T', display: 'Transient' }],
    }),
];

const STATUS_DISPLAY = {
    PLANNED: 'Planned (approved)',
    ACTIVE: 'Active',
    WAITLIST: 'Waitlist (approved)',
    ELIGIBILITY_NOT_DETERMINED: 'Eligibility not determined',
    NOT_ELIGIBLE: 'Not eligible',
};

const UI_TO_FHIR_STATUS = {
    [STATUS_DISPLAY.ELIGIBILITY_NOT_DETERMINED]: 'planned',
    [STATUS_DISPLAY.NOT_ELIGIBLE]: 'cancelled',
    [STATUS_DISPLAY.PLANNED]: 'planned',
    [STATUS_DISPLAY.WAITLIST]: 'waitlist',
    [STATUS_DISPLAY.ACTIVE]: 'active',
};

const UI_TO_ELIGIBILITY_STATUS = {
    [STATUS_DISPLAY.ELIGIBILITY_NOT_DETERMINED]: 'TBD',
    [STATUS_DISPLAY.NOT_ELIGIBLE]: 'NotEligible',
    [STATUS_DISPLAY.PLANNED]: null,
    [STATUS_DISPLAY.WAITLIST]: null,
    [STATUS_DISPLAY.ACTIVE]: null,
};

// Add this to map FHIR statuses back to UI display
const FHIR_TO_UI_STATUS = {
    planned: STATUS_DISPLAY.ELIGIBILITY_NOT_DETERMINED, // Default mapping, might be overridden by eligibilityStatus
    cancelled: STATUS_DISPLAY.NOT_ELIGIBLE,
    waitlist: STATUS_DISPLAY.WAITLIST,
    active: STATUS_DISPLAY.ACTIVE,
};

const STATUS_REQUIREMENTS = {
    [STATUS_DISPLAY.ELIGIBILITY_NOT_DETERMINED]: {
        requiresDatetime: false,
        requiresRationale: false,
        requiresExternalReferral: false,
    },
    [STATUS_DISPLAY.NOT_ELIGIBLE]: {
        requiresRationale: true,
        requiresExternalReferral: true,
        rationales: ['Does not meet program criteria', 'Out of service area', 'Insurance not accepted', 'Service not available', 'Other'],
    },
    [STATUS_DISPLAY.PLANNED]: {
        requiresEstimatedDateTime: true,
        requiresActualDateTime: true,
    },
    [STATUS_DISPLAY.WAITLIST]: {
        requiresRationale: true,
        requiresEstimatedDateTime: true,
        rationales: ['Does not meet program criteria', 'Out of service area', 'Insurance not accepted', 'Service not available', 'Other'],
    },
    [STATUS_DISPLAY.ACTIVE]: {
        requiresStartDateTime: true,
    },
};

const episodeOfCareStatusData = [
    {
        code: 'planned',
        display: STATUS_DISPLAY.PLANNED,
        requiresEstimatedDateTime: true,
        requiresActualDateTime: true,
    },
    {
        code: 'active',
        display: STATUS_DISPLAY.ACTIVE,
        requiresStartDateTime: true,
    },

    {
        code: 'waitlist',
        display: STATUS_DISPLAY.WAITLIST,
        requiresRationale: true,
        requiresEstimatedDateTime: true,
        rationales: ['Does not meet program criteria', 'Out of service area', 'Insurance not accepted', 'Service not available', 'Other'],
    },
    {
        code: 'eligibility-not-determined',
        display: STATUS_DISPLAY.ELIGIBILITY_NOT_DETERMINED,
        requiresDatetime: false,
    },
    {
        code: 'not-eligible',
        display: STATUS_DISPLAY.NOT_ELIGIBLE,
        requiresRationale: true,
        requiresExternalReferral: true,
        rationales: ['Does not meet program criteria', 'Out of service area', 'Insurance not accepted', 'Service not available', 'Other'],
    },
];

export default {
    name: 'BnContactEditHuman',
    components: {
        BnTooltip,
        BnRelated,
        BnInfo,
        BnListIdentifiers,
        BnListQualifications,
        BnContactAddress,
        BnContactPointEdit,
        BnDialog,
        BnListBox,
        BnImageUploader,
        DataProvider: dataProvider,
        BnDatePicker,
    },
    props: {
        resource: Object,
        relationshipProps: Object,
        authStore: Object,
        isWidget: Boolean,
        autosave: Boolean,
        validations: Object,
    },
    emits: ['validate', 'showAccess', 'isPatientOrContact', 'eocPrepared'],
    setup() {
        const { hasAccessToProgram } = usePatientAccess();
        return { v$: useVuelidate(), hasAccessToProgram };
    },
    data() {
        return {
            humanData: null,
            dpAgencies: [],
            dpLocations: [],
            useValues: traits.contactPointTraits.contactPointUseValues,
            emailUseValues: traits.contactPointTraits.contactEmailUseValues,
            genderOptions: [],
            religiousAffiliationOptions: [],
            pronounOptions: [],
            sexualOrientationOptions: [],
            genderIdentityOptions: [],
            maritalStatusOptions: [],
            raceOptions: [],
            preferredLanguages: [],
            ethnicityDetailedOptions: [],
            sameAsHome: true,
            timezoneData: timezoneApi.loadPickerByRegion(),
            dpData: null,
            qualifications: [],
            raceCategoryOptions: raceCategoryData,
            raceDetailedOptions: [],
            raceDetailedSearch: '',
            raceParentLookup: null,
            ethnicityDetailedSearch: '',
            debounceSearch: null,
            loadingRaceDetailedSearch: false,
            ethnicityCategoryOptions: ethnicityCategoryData,
            phoneUseIcon: {
                mobile: 'mobile',
                home: 'phone-rotary',
                work: 'phone-office',
            },
            phoneUseValues: {
                mobile: 'Mobile',
                home: 'Home',
                work: 'Work',
            },
            emailUseIcon: {
                home: 'at',
                work: 'briefcase',
            },
            emailUseTextValues: {
                home: 'Personal',
                work: 'Work',
            },
            addressUseIcon: {
                home: 'home',
                work: 'building',
                billing: 'mailbox',
            },
            addressUseValues: {
                home: 'Home',
                work: 'Work',
                billing: 'Mailing',
            },
            workingContactPoint: null,
            showContactPointDialog: false,
            workingAddress: null,
            showAddressDialog: false,
            bithDateIsValid: true,
            livingArrangementOptions: livingArrangementData,
            statusCategoryOptions: episodeOfCareStatusData,
            episodeOfCareStatusData,
            statusRationale: '',
            hasExternalReferral: false,
            referralDateTime: null,
            referralUser: null,
            estimatedDateTime: null,
            actualDateTime: null,
            startDateTime: null,
            selectedRationale: '',
            customRationale: '',
            referringUser: null,
        };
    },
    validations() {
        return merge(this.validations, {
            humanData: {
                firstName: {
                    required,
                },
                ssn: {
                    minLength: minLength(11),
                },
                birthDate: {
                    customValidation: () => {
                        return this.isBirthDateValid;
                    },
                },
                temp: {
                    program: {
                        required: requiredIf(() => {
                            return this.isPatient && !this.humanData?.id;
                        }),
                    },
                },
                // Custom identifier fields must either both be filled or both be empty
                customIdentifierText: {
                    required: requiredIf(() => {
                        return this.customIdentifierValue && !this.customIdentifierText;
                    }),
                },
                customIdentifierValue: {
                    required: requiredIf(() => {
                        return this.customIdentifierText && !this.customIdentifierValue;
                    }),
                },
            },
        });
    },
    computed: {
        dpAgenciesQuery() {
            return [{ query: { partof: this.authStore.account.id, _sort: 'name' }, resourceType: 'Organization' }];
        },
        showImageUploader() {
            return !this.isWidget;
        },
        isBirthDateValid() {
            return this.bithDateIsValid;
        },
        isPatient() {
            return isPatient(this.resource);
        },
        sortedRaceDetailedOptions() {
            return [...this.raceDetailedOptions].sort((a, b) => {
                return a.display > b.display ? 1 : -1;
            });
        },
        photo: {
            get: function () {
                return this.humanData?.photo?.[0]?.url;
            },
            set: function (newValue) {
                this.humanData.photo = [new FHIRWorksTypes.Attachment({ url: newValue })];
            },
        },
        dpLocationsQuery() {
            return [
                {
                    query: '_include=organization&_include=location&practitioner=' + this.authStore?.practitioner?.id,
                    resourceType: 'PractitionerRole',
                },
                { query: 'active=true&isProgram=true', resourceType: 'HealthcareService' },
            ];
        },
        locations() {
            let locations = [];
            this.dpLocations
                .filter((item) => item.resourceType === 'Organization')
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                ?.forEach((brand) => {
                    this.dpLocations
                        .filter((item) => item.resourceType === 'Location' && item.managingOrganization?.id === brand.id)
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        ?.forEach((item) => {
                            locations.push({ id: item.id, display: item.name, managingOrganization: { id: brand.id, display: brand.name, resourceType: 'Organization' } });
                        });
                });
            return locations;
        },
        programs() {
            return (
                this.dpLocations
                    .filter((item) => item.resourceType === 'HealthcareService' && item.isProgram && item.location.some((loc) => loc.id === this.humanData.temp.location?.id))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .flatMap((item) => {
                        return { id: item.id, display: item.name, resourceType: item.resourceType };
                    }) || []
            );
        },
        eoc() {
            return this.humanData?.temp;
        },
        defaultProgram() {
            return this.dpLocations.find((item) => item.id === this.eoc?.location?.id)?.defaultProgram;
        },

        //Abdel code
        currentStatus: {
            get() {
                // First check for special eligibility statuses
                if (this.eoc.eligibilityStatus === 'TBD') {
                    return STATUS_DISPLAY.ELIGIBILITY_NOT_DETERMINED;
                }
                if (this.eoc.eligibilityStatus === 'NotEligible') {
                    return STATUS_DISPLAY.NOT_ELIGIBLE;
                }

                // If no special eligibility status, map from FHIR status
                const fhirStatus = this.eoc.status || 'planned';
                return FHIR_TO_UI_STATUS[fhirStatus] || STATUS_DISPLAY.PLANNED;
            },
            set(newStatus) {
                // Get FHIR status and eligibility status
                const fhirStatus = UI_TO_FHIR_STATUS[newStatus];
                const eligibilityStatus = UI_TO_ELIGIBILITY_STATUS[newStatus];

                // Update the EOC status
                this.eoc.status = fhirStatus;

                // Handle eligibility status
                if (eligibilityStatus === null) {
                    delete this.eoc.eligibilityStatus;
                } else {
                    this.eoc.eligibilityStatus = eligibilityStatus;
                }

                // Reset fields when status changes
                this.handleStatusChange(newStatus);
            },
        },

        currentStatusRequirements() {
            return STATUS_REQUIREMENTS[this.currentStatus] || {};
        },

        showRationaleInput() {
            return this.currentStatusRequirements.requiresRationale;
        },

        showExternalReferral() {
            return this.currentStatusRequirements.requiresExternalReferral;
        },

        showEstimatedDateTime() {
            return this.currentStatusRequirements.requiresEstimatedDateTime;
        },

        showActualDateTime() {
            return this.currentStatusRequirements.requiresActualDateTime;
        },

        showStartDateTime() {
            return this.currentStatusRequirements.requiresStartDateTime;
        },
        //End Abdel code
        customIdentifierText: {
            get() {
                return this.humanData.customIdentifier ? this.humanData.customIdentifier.text : '';
            },
            set(value) {
                // Manually trigger the setter for customIdentifier
                this.humanData.customIdentifier = { text: value, value: this.customIdentifierValue };
            },
        },

        customIdentifierValue: {
            get() {
                return this.humanData.customIdentifier ? this.humanData.customIdentifier.value : '';
            },
            set(value) {
                // Manually trigger the setter for customIdentifier
                this.humanData.customIdentifier = { text: this.customIdentifierText, value };
            },
        },
    },
    watch: {
        bithDateIsValid: {
            handler() {
                this.validateData();
            },
            immediate: true,
        },
        humanData: {
            async handler() {
                this.validateData();
            },
            immediate: true,
            deep: true,
        },
        raceDetailedSearch(value) {
            if (this.debounceSearch) {
                this.debounceSearch(value);
                return;
            }
            this.debounceSearch = debounce(this.searchRaceDetails, 500);
        },

        'eoc.status'(newStatus) {
            this.handleStatusChange(newStatus);
        },
    },
    mounted() {
        if (this.relationshipProps?.scrollToControlRef) {
            this.$nextTick(() => {
                this.$refs[this.relationshipProps.scrollToControlRef].$el.scrollIntoView({ behavior: 'smooth' });
                // setTimeout used to pull the whole field into view
                setTimeout(() => this.$refs[this.relationshipProps.scrollToControlRef].focus(), 150);
            });
        }
    },
    async created() {
        // Load race detailed options so they are displayed
        this.raceDetailedOptions = this.resource.raceDetailed ? this.resource.raceDetailed.map((item) => item) : [];

        // languages,
        pronouns().then((options) => {
            if (options) {
                options = sortByList(
                    options.map((e) => e.resource),
                    ['LA29518-0', 'LA29519-8'],
                    'code',
                    'display',
                );
                this.pronounOptions = options.map((e) => {
                    return new FHIRWorksTypes.Coding({
                        system: e.system,
                        code: e.code,
                        display: e.display,
                    });
                });
            }
        });

        sexualOrientation().then((options) => {
            if (options) {
                this.sexualOrientationOptions = options.map((e) => {
                    return new FHIRWorksTypes.Coding({
                        system: e.resource.system,
                        code: e.resource.code,
                        display: e.resource.display,
                    });
                });
            }
        });

        genderIdentity().then((options) => {
            if (options) {
                options = sortByList(
                    options.map((e) => e.resource),
                    ['446151000124109', '446141000124107', 'ASKU'],
                    'code',
                    'display',
                );
                this.genderIdentityOptions = options.map((e) => {
                    return new FHIRWorksTypes.CodeableConcept({
                        title: e.display,
                        coding: [{ system: e.system, code: e.code, display: e.display }],
                    });
                });
            }
        });

        maritalStatus().then((options) => {
            if (options) {
                options = options.map((e) => e.resource).sort((a, b) => (a.display > b.display ? 1 : -1));
                this.maritalStatusOptions = options.map((e) => {
                    return new FHIRWorksTypes.CodeableConcept({
                        title: e.display,
                        coding: [{ system: e.system, code: e.code, display: e.display.toLowerCase().charAt(0).toUpperCase() + e.display.toLowerCase().slice(1) }],
                    });
                });
                // Dedupe options
                this.maritalStatusOptions = [...new Map(this.maritalStatusOptions.map((i) => [i.coding[0].code, i])).values()];
            }
        });

        languages().then((options) => {
            if (options) {
                options = sortByList(
                    options.map((e) => e.resource),
                    ['en', 'es', 'fr', 'de'],
                    'code',
                    'display',
                );
                this.preferredLanguages = options.map((e) => {
                    let languageCodeableConceptJson = {
                        coding: [{ system: 'urn:ietf:bcp:47', code: e.code, display: e.display }],
                        title: e.display,
                    };

                    if (this.resource.resourceType === 'Practitioner') {
                        return new FHIRWorksTypes.CodeableConcept(languageCodeableConceptJson);
                    }
                    // Otherwise this is a Patient
                    return new PatientCommunication({
                        language: languageCodeableConceptJson,
                        preferred: true,
                    });
                });
            }
        });

        religiousAffiliation().then((options) => {
            if (options) {
                this.religiousAffiliationOptions = options.map((e) => {
                    return new FHIRWorksTypes.CodeableConcept({
                        text: e.resource.display,
                        coding: [{ code: e.resource.code, display: e.resource.display }],
                    });
                });
            }
        });

        race().then((options) => {
            if (options) {
                this.raceOptions = options.map((e) => {
                    return new FHIRWorksTypes.Coding({
                        system: e.resource.system,
                        code: e.resource.code,
                        display: e.resource.display,
                    });
                });
            }
        });

        ethnicityDetail().then((options) => {
            if (options) {
                this.ethnicityDetailedOptions = options.map((e) => {
                    return new FHIRWorksTypes.Coding({
                        code: e.resource.code,
                        system: ethnicityCategoryCodeSystem,
                        display: e.resource.display,
                    });
                });
            }
        });

        administrativeGender().then((options) => {
            // debugger;
            if (options) {
                this.genderOptions = sortByList(
                    options.map((e) => e.resource),
                    ['male', 'female', 'other', 'unknown'],
                    'code',
                );
            }
        });
    },
    methods: {
        prepareEpisodeOfCare() {
            if (!this.humanData?.temp || Object.keys(this.humanData.temp).length === 0) return;

            // Handle status mapping explicitly
            let fhirStatus = 'planned';
            let eligibilityStatus = 'TBD';

            if (this.eoc.status === 'eligibility-not-determined') {
                fhirStatus = 'planned';
                eligibilityStatus = 'TBD';
            } else if (this.eoc.status === 'not-eligible') {
                fhirStatus = 'cancelled';
                eligibilityStatus = 'NotEligible';
            } else {
                fhirStatus = this.eoc.status;
                eligibilityStatus = null;
            }

            return {
                status: fhirStatus,
                eligibilityStatus,
                managingOrganization: {
                    reference: `Organization/${this.eoc.agency.id}`,
                    display: this.eoc.agency.name,
                    resourceType: 'Organization',
                },
                location: {
                    id: this.eoc.location.id,
                    reference: `Location/${this.eoc.location.id}`,
                    display: this.eoc.location.display,
                    resourceType: 'Location',
                },
                program: {
                    id: this.eoc.program.id,
                    reference: `HealthcareService/${this.eoc.program.id}`,
                    display: this.eoc.program.display,
                    resourceType: 'HealthcareService',
                },
                period: this.startDateTime
                    ? {
                          start: this.startDateTime,
                      }
                    : undefined,
                estPeriod: this.eoc.eocEstStartDate
                    ? {
                          start: this.eoc.eocEstStartDate,
                      }
                    : undefined,
                statusReason: this.eoc.statusReason,
            };
        },

        setBirthDateisValid(isValid) {
            this.bithDateIsValid = isValid;
        },
        handleAddressLatLngChange: function (locationInfo) {
            if (!locationInfo) {
                this.humanData.timeZone = '';
                return;
            }
            getGoogleTimeZoneInfo(locationInfo).then(
                (res) => {
                    this.humanData.timeZone = res.data.timeZoneId;
                },
                () => {},
            );
        },
        programAccess(program, location) {
            const accessStatus = this.hasAccessToProgram(program, location);
            this.$emit('showAccess', accessStatus);
            return accessStatus;
        },
        practitionerLoaded() {
            // load existing qualification
            this.humanData?.qualification.forEach((item) => this.qualifications.push(item.code.coding[0]));
        },
        ethnicityCategoryChangeHandler(ethnicityCategory) {
            if (!(ethnicityCategory instanceof FHIRWorksTypes.Coding)) {
                return;
            }

            if (ethnicityCategory.code === ethnicityCategoryNotHispanicLatinoCode) {
                this.humanData.ethnicityDetailed = undefined;
            }
        },
        ethnicityDetailedChangeHandler(detailItems) {
            this.ethnicityDetailedSearch = '';
            if (!Array.isArray(detailItems) || detailItems.length === 0) {
                return;
            }

            if (!this.humanData.ethnicityCategory || this.humanData.ethnicityCategory.code !== ethnicityCategoryHispanicLatinoCode) {
                this.humanData.ethnicityCategory = ethnicityCategoryData[0];
            }
        },
        async searchRaceDetails(searchVal) {
            // clear results if no searchVal
            if (!searchVal) {
                this.raceDetailedOptions = this.resource.raceDetailed?.map((item) => item) || [];
                return;
            }

            // search
            this.loadingRaceDetailedSearch = true;
            if (!this.raceParentLookup) {
                this.raceParentLookup = new Map();
            }

            let queryString = '/Concept?valueset=v3-Race&hierarchy=' + raceCategoryLevel1Codes.join(',') + '&_sort=display&display=' + searchVal;
            await $httpTerminology.get(queryString).then(async (response) => {
                let results = response.data.entry.map((entryItem) => createFhirResource(entryItem.resource.resourceType, entryItem.resource));

                // reset list to currently selected items
                this.raceDetailedOptions = this.resource.raceDetailed?.map((item) => item) || [];

                // add new search results
                results.forEach((concept) => {
                    if (!this.raceParentLookup.has(concept.code)) {
                        this.raceParentLookup.set(concept.code, concept.hierarchy);
                    }

                    // Check if the race item already exist in the detailed options array
                    if (this.raceDetailedOptions.find((item) => item.code === concept.code)) {
                        return;
                    }
                    this.raceDetailedOptions.push(
                        new FHIRWorksTypes.Coding({
                            code: concept.code,
                            system: raceCategoryCodeSystem,
                            display: concept.display,
                        }),
                    );
                });
            });
            this.loadingRaceDetailedSearch = false;
        },
        raceDetailedChangeHandler(detailItems) {
            if (!this.raceParentLookup) {
                this.raceParentLookup = new Map();
            }
            this.raceDetailedSearch = '';

            detailItems.forEach(async (item) => {
                if (!this.raceParentLookup.has(item.code)) {
                    let queryString = '/Concept?valueset=v3-Race&_count=1&code=' + item.code;
                    await $httpTerminology.get(queryString).then((response) => {
                        let results = response.data.entry.map((entryItem) => createFhirResource(entryItem.resource.resourceType, entryItem.resource));

                        results.forEach((concept) => {
                            this.raceParentLookup.set(concept.code, concept.hierarchy);
                        });
                    });
                }

                let parentTopCode = this.raceParentLookup.get(item.code)[0];
                if (this.humanData.raceCategories.find((item) => item.code === parentTopCode)) {
                    return;
                }

                this.humanData.raceCategories = this.raceCategoryOptions.find((item) => {
                    return item.code === parentTopCode;
                });
            });
        },
        addPhone(phoneOption) {
            // If no phone parameter passed (mouse event found), default to mobile.
            if (phoneOption instanceof Event) phoneOption = 'mobile';

            let disablePreferredCheckBox = false;
            let contactJson = { system: 'phone', use: phoneOption };
            if (!this.humanData.allPhones.length) {
                contactJson.rank = 1;
                disablePreferredCheckBox = true;
            }
            this.workingContactPoint = new ContactPoint(contactJson);
            this.workingContactPoint.temp.mode = 'add';
            this.workingContactPoint.temp.disablePreferred = disablePreferredCheckBox;
            this.showContactPointDialog = true;
        },
        editPhone(phone) {
            this.workingContactPoint = phone;
            this.workingContactPoint.temp.mode = 'edit';
            this.workingContactPoint.temp.disablePreferred = false;
            if (this.workingContactPoint.rank === 1 && this.humanData.allPhones.length > 1) {
                this.workingContactPoint.temp.disablePreferred = true;
            }
            this.showContactPointDialog = true;
        },
        addEmail(emailOption) {
            // If no phone parameter passed (mouse event found), default to mobile.
            if (emailOption instanceof Event) emailOption = 'home';

            let disablePreferredCheckBox = false;
            let emailJson = { system: 'email', use: emailOption };
            if (!this.humanData.allEmails.length) {
                emailJson.rank = 1;
                disablePreferredCheckBox = true;
            }
            this.workingContactPoint = new ContactPoint(emailJson);
            this.workingContactPoint.temp.mode = 'add';
            this.workingContactPoint.temp.disablePreferred = disablePreferredCheckBox;
            this.showContactPointDialog = true;
        },
        editEmail(email) {
            this.workingContactPoint = email;
            this.workingContactPoint.temp.mode = 'edit';
            this.showContactPointDialog = true;
        },
        removeContactPoint(contactPoint) {
            let contactIndex = this.humanData.telecom.indexOf(contactPoint);
            if (contactIndex >= 0) {
                // If we are deleting a mobile phone that is the value for sendSms, clear
                // the resource sendSms value
                if (contactPoint.system === 'phone' && contactPoint.use === 'mobile' && this.humanData.sendSms === contactPoint.value) {
                    this.humanData.sendSms = undefined;
                }
                this.humanData.telecom.splice(contactIndex, 1);
            }
        },
        addAddress(addressOption) {
            // If no phone parameter passed (mouse event found), default to mobile.
            if (addressOption instanceof Event) addressOption = 'home';

            let disablePreferredCheckBox = false;
            this.workingAddress = new Address({ use: addressOption });
            if (!this.humanData.address.length) {
                this.workingAddress.preferred = true;
                disablePreferredCheckBox = true;
            }
            this.workingAddress.temp.mode = 'add';
            this.workingAddress.temp.disablePreferred = disablePreferredCheckBox;
            this.showAddressDialog = true;
        },
        editAddress(address) {
            this.workingAddress = address;
            this.workingAddress.temp.mode = 'edit';
            this.workingAddress.temp.disablePreferred = false;
            if (this.workingAddress.preferred && this.humanData.address.length) {
                this.workingAddress.temp.disablePreferred = true;
            }
            this.showAddressDialog = true;
        },
        removeAddress(addressOption) {
            let addressIndex = this.humanData.address.indexOf(addressOption);
            if (addressIndex >= 0) {
                this.humanData.address.splice(addressIndex, 1);
            }
        },
        sortedAddress(addresses) {
            let addressSortOrder = {
                home: 1,
                mailing: 2,
                billing: 2,
                work: 3,
            };
            return addresses.map((address) => address).sort((a, b) => (a.preferred ? 0 : 1) - (b.preferred ? 0 : 1) || (addressSortOrder[a.use] > addressSortOrder[b.use] ? 1 : -1));
        },

        // Practitioner identifier/qualifications
        setIdentifier(identifier) {
            this.humanData.identifier = identifier;
        },

        resetPronounSelect() {
            this.humanData.pronouns = undefined;
        },

        // EOC
        initEOC() {
            this.setEOCComplaint('Unspecified');
            const defaultAgency = this.authStore.brand;
            const defaultLocation = this.locations.find((item) => item.id === this.authStore.brand.location.id);
            this.setEOCAgency(defaultAgency);
            this.setEOCLocation(defaultLocation);
            this.setEOCStatus('planned');
        },
        setEOCComplaint(value) {
            this.humanData.temp.complaint = value;
        },
        setEOCLocation(value) {
            this.humanData.temp.location = value;
            const defaultProgram = this.programs.find((item) => item.id === this.defaultProgram?.id) || this.programs[0];
            this.humanData.temp.program = defaultProgram;
        },

        setEOCAgency(value) {
            // Set the agency
            this.humanData.temp.agency = value;
            // Set the managingOrganization
            this.humanData.temp.managingOrganization = {
                reference: `Organization/${value.id}`,
                display: value.name,
                resourceType: 'Organization_dev-agency',
            };
        },
        setEOCStatus(value) {
            this.humanData.temp.status = value;
        },
        setEOCEstStartDate(value) {
            this.humanData.temp.eocEstStartDate = value;
        },
        setStatusReason(value) {
            this.humanData.temp.statusReason = value;
        },
        setPlacementStatus(value) {
            this.humanData.temp.placementStatus = value;
        },
        async saveRelationship(newContact) {
            return await this.$refs.contactRelationship.saveNewContactRelationship(newContact);
        },
        closeMenu(e) {
            if (this.$refs[e.toString()]) {
                setTimeout(() => {
                    this.$refs[e.toString()].isMenuActive = false;
                }, 10);
            }
        },
        setBirthDate(date) {
            this.humanData.birthDate = date;
        },
        async validateData() {
            let valid = await this.v$.humanData?.$validate();

            if (!valid) {
                this.v$.humanData.$touch();
            }
            this.$emit('isPatientOrContact', this.isPatient);
            this.$emit('validate', !valid);
        },
        isResourceUser(resource) {
            return isUser(resource);
        },

        handleStatusChange(newStatus) {
            this.eoc.status = newStatus;

            // Reset fields when status changes
            this.statusRationale = '';
            this.hasExternalReferral = false;
            this.referralDateTime = null;
            this.referralUser = null;
            this.estimatedDateTime = null;
            this.actualDateTime = null;
            this.startDateTime = null;
            this.selectedRationale = '';
            this.customRationale = '';
            this.referringUser = null;

            // If status is active, automatically set program dates
            if (newStatus === 'active' && this.startDateTime) {
                this.setProgramDates(this.startDateTime);
            }
        },

        setProgramDates(datetime) {
            if (this.eoc.program) {
                this.eoc.program.startDateTime = datetime;
            }
        },

        setRationale() {
            this.statusRationale = this.selectedRationale === 'Other' ? this.customRationale : this.selectedRationale;
        },

        handleExternalReferral(value) {
            this.hasExternalReferral = value;
            if (!value) {
                this.referralDateTime = null;
                this.referralUser = null;
                this.referringUser = null;
            }
        },
    },
};
</script>

<template>
    <DataProvider v-model="humanData" :autosave="autosave" :resource="resource" :validator="v$.humanData">
        <template #default>
            <template v-if="$vuetify.display.xs">
                <v-row>
                    <v-col v-if="showImageUploader">
                        <BnImageUploader
                            ref="humanData"
                            v-model="photo"
                            :resource="humanData"
                            :options="{ size: 48 }"
                            :alt="humanData.resourceType + ' image'"
                            :contact-edit="true"
                            :avatar="true"
                            :vocab="authStore.clientVocab"
                            :is-public="humanData.resourceType !== 'Patient'"
                            placeholder
                            class="d-flex py-0 justify-center"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex py-0">
                        <v-text-field
                            v-model="humanData.firstName"
                            label="First name"
                            autofocus
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'firstName', true)"
                            data-cy="contactEditHumanFirstName"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                            @blur="v$.humanData.firstName.$touch()"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex py-0">
                        <v-text-field
                            v-model="humanData.lastName"
                            label="Last name"
                            data-cy="contactEditHumanLastName"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'lastName', true)"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex py-0">
                        <v-text-field v-model="humanData.middleName" label="Middle name" data-cy="contactEditHumanMiddleName" :class="{ usprivacy: getUSPrivacy(resource) }"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex py-0">
                        <v-text-field v-model="humanData.preferredName" label="Preferred name" data-cy="contactEditHumanPreferredName" :class="{ usprivacy: getUSPrivacy(resource) }">
                            <template #append>
                                <BnInfo type="dialog" size="lg"> The individual's preferred name. If specified the preferred name will display in quotes after their first name. </BnInfo>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex py-0">
                        <v-text-field
                            v-model="humanData.givenPhoneticSpelling"
                            label="First name phonetic spelling"
                            data-cy="contactEditHumanGivenPhoneticSpelling"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                        >
                            <template #append>
                                <BnInfo type="dialog" size="lg">
                                    Spelling of the individuals name the way it sounds. Phonetic spelling will display in parentheses after their first name IF preferred name is not specified.
                                </BnInfo>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- Maiden or prior name-->
                    <v-col v-if="humanData.resourceType === 'Patient'" class="d-flex py-0">
                        <v-text-field v-model="humanData.maidenName" label="Maiden or prior name" :class="{ usprivacy: getUSPrivacy(resource) }"> </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0">
                        <BnDatePicker
                            id="contactEditHumanDateOfBirth"
                            v-model="humanData.birthDate"
                            dob
                            :input-date-format="authStore.inputDateFormat"
                            label="Date of birth"
                            :passed-class="getUSPrivacy(resource) ? 'usprivacy' : ''"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'birthDate', true)"
                            @update:model-value="setBirthDate"
                        />
                    </v-col>
                </v-row>
                <!--MRN-->
                <v-row v-if="isPatient">
                    <v-col class="py-0">
                        <v-text-field v-model="humanData.mrn" label="MRN" :class="{ usprivacy: getUSPrivacy(resource) }" :disabled="true"></v-text-field>
                    </v-col>
                </v-row>
                <!--Custom Identifier Label-->
                <v-row>
                    <v-col class="py-0">
                        <v-text-field v-model="customIdentifierText" label="Custom ID number label" v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'customIdentifierText', true)" style="flex: 1" />
                    </v-col>
                </v-row>
                <!--Custom Identifier Value-->
                <v-row>
                    <v-col class="py-0">
                        <v-text-field
                            v-model="customIdentifierValue"
                            label="Custom ID number"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'customIdentifierValue', true)"
                            style="flex: 1"
                            class="usprivacy"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <!-- Row 1: First, Middle, Last Names, Photo-->
                <v-row>
                    <v-col class="d-flex pb-0">
                        <v-text-field
                            v-model="humanData.firstName"
                            label="First name"
                            autofocus
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'firstName', true)"
                            data-cy="contactEditHumanFirstName"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                            @blur="v$.humanData.firstName.$touch()"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col class="d-flex pb-0">
                        <v-text-field v-model="humanData.middleName" label="Middle name" data-cy="contactEditHumanMiddleName" :class="{ usprivacy: getUSPrivacy(resource) }"></v-text-field>
                    </v-col>
                    <v-col class="d-flex pb-0">
                        <v-text-field
                            v-model="humanData.lastName"
                            label="Last name"
                            data-cy="contactEditHumanLastName"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'lastName', true)"
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="showImageUploader" class="d-flex justify-center align-center py-0">
                        <BnImageUploader
                            ref="humanData"
                            v-model="photo"
                            :resource="humanData"
                            :options="{ size: 48 }"
                            :alt="humanData.resourceType + ' image'"
                            :contact-edit="true"
                            :avatar="true"
                            :vocab="authStore.clientVocab"
                            :is-public="humanData.resourceType !== 'Patient'"
                            placeholder
                            class="align-self-center py-0"
                        />
                    </v-col>
                </v-row>
                <!-- Row 2: Preferred name, first name phonetic, maiden/prior name-->
                <v-row>
                    <v-col cols="3" class="d-flex py-0">
                        <v-text-field v-model="humanData.preferredName" label="Preferred name" data-cy="contactEditHumanPreferredName" :class="{ usprivacy: getUSPrivacy(resource) }">
                            <template #append>
                                <BnInfo type="dialog" size="lg"> The individual's preferred name. If specified the preferred name will display in quotes after their first name. </BnInfo>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="3" class="d-flex py-0">
                        <v-text-field
                            v-model="humanData.givenPhoneticSpelling"
                            label="First name phonetic spelling"
                            data-cy="contactEditHumanGivenPhoneticSpelling"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                        >
                            <template #append>
                                <BnInfo type="dialog" size="lg">
                                    Spelling of the individuals name the way it sounds. Phonetic spelling will display in parentheses after their first name IF preferred name is not specified.
                                </BnInfo>
                            </template>
                        </v-text-field>
                    </v-col>
                    <!-- Patient: Maiden or prior name. Others: DOB-->
                    <v-col v-if="humanData.resourceType === 'Patient'" cols="3" class="d-flex py-0">
                        <v-text-field v-model="humanData.maidenName" label="Maiden or prior name" :class="{ usprivacy: getUSPrivacy(resource) }" data-cy="contactEditHumanMaidenName"> </v-text-field>
                    </v-col>
                    <v-col v-else cols="3" class="py-0">
                        <BnDatePicker
                            id="contactEditHumanDateOfBirth"
                            v-model="humanData.birthDate"
                            dob
                            :input-date-format="authStore.inputDateFormat"
                            label="Date of birth"
                            :passed-class="getUSPrivacy(resource) ? 'usprivacy' : ''"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'birthDate', true)"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                            data-cy="contactEditHumanBirthDate"
                            @update:model-value="setBirthDate"
                            @is-date-valid="setBirthDateisValid"
                        />
                    </v-col>
                </v-row>
                <!-- Row 3: Date of Birth (For Patients) + MRN + Custom ID -->
                <v-row>
                    <v-col v-if="humanData.resourceType === 'Patient'" cols="3" class="pt-0">
                        <BnDatePicker
                            id="contactEditHumanDateOfBirth"
                            v-model="humanData.birthDate"
                            dob
                            :input-date-format="authStore.inputDateFormat"
                            label="Date of birth"
                            :passed-class="getUSPrivacy(resource) ? 'usprivacy' : ''"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'birthDate', true)"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                            data-cy="contactEditHumanBirthDate"
                            @update:model-value="setBirthDate"
                            @is-date-valid="setBirthDateisValid"
                        />
                    </v-col>
                    <v-col v-if="isPatient" cols="3" class="pt-0">
                        <v-text-field v-model="humanData.mrn" label="MRN" :class="{ usprivacy: getUSPrivacy(resource) }" :disabled="true"></v-text-field>
                    </v-col>
                    <v-col cols="3" class="pt-0">
                        <v-text-field v-model="customIdentifierText" label="Custom ID number label" v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'customIdentifierText', true)" style="flex: 1" />
                    </v-col>
                    <v-col cols="3" class="pt-0">
                        <v-text-field
                            v-model="customIdentifierValue"
                            label="Custom ID number"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'customIdentifierValue', true)"
                            style="flex: 1"
                            class="usprivacy"
                        />
                    </v-col>
                </v-row>
            </template>

            <BnRelated
                v-if="relationshipProps && relationshipProps.relationSubject && relationshipProps.relationSubject.id"
                ref="contactRelationship"
                :subject-id="relationshipProps.relationSubject.id"
                :subject-resource-type="relationshipProps.relationSubject.resourceType"
                :adding-new-contact="true"
                :new-contact-data="humanData"
                :is-emergency-contact="relationshipProps.isEmergencyContact"
                :is-legal-guardian="relationshipProps.isLegalGuardian"
                data-cy="contactEditHumanRelationship"
            ></BnRelated>

            <!-- New Patient Episode of Care details -->
            <template v-if="isPatient && !humanData.id">
                <div class="bn-section-header">Initial Episode of Care</div>
                <br />

                <v-row>
                    <v-col class="d-flex py-0">
                        <!-- Agency -->
                        <DataProvider v-model="dpAgencies" collection refresh-on-change :query="dpAgenciesQuery" />
                        <v-autocomplete
                            v-model="eoc.agency"
                            label="Agency"
                            :items="dpAgencies"
                            item-title="display"
                            return-object
                            data-cy="patientLocation"
                            class="agency-select"
                            @update:model-value="setEOCAgency"
                        >
                            <template #item="{ item, props }">
                                <v-list-item v-bind="props" :title="null" class="agency-list-item">
                                    <div class="d-flex align-center w-100 gap-4">
                                        <font-awesome-icon :icon="['far', 'building-columns']" class="flex-shrink-0 mr-4" />
                                        <div class="text-truncate">
                                            {{ item.value.name }}
                                        </div>
                                    </div>
                                </v-list-item>
                            </template>
                            <template #selection="{ item }">
                                <div class="d-flex align-center w-100 gap-4">
                                    <font-awesome-icon :icon="['far', 'building-columns']" class="flex-shrink-0 mr-4" />
                                    <div class="text-truncate">
                                        {{ item.value.name }}
                                    </div>
                                </div>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col class="d-flex py-0">
                        <!-- Location -->
                        <DataProvider v-model="dpLocations" collection :query="dpLocationsQuery" @loaded="initEOC" />
                        <v-autocomplete v-model="eoc.location" label="Location" :items="locations" item-title="display" return-object data-cy="patientLocation" @update:model-value="setEOCLocation">
                            <template #item="{ item, props }">
                                <v-list-item v-bind="props" :title="null">
                                    <font-awesome-icon :icon="['far', 'map-marker-alt']" class="mr-4" />
                                    {{ item.title }}
                                </v-list-item>
                            </template>
                            <template #selection="{ item }">
                                <font-awesome-icon :icon="['far', 'map-marker-alt']" class="mr-4" />
                                {{ item.title }}
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" class="py-0">
                        <v-autocomplete
                            v-model="eoc.program"
                            label="Program"
                            :items="programs"
                            item-title="display"
                            return-object
                            data-cy="patientProgram"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData.temp, 'program', true)"
                            class="program-select"
                            @blur="v$.humanData.temp.program.$touch()"
                        >
                            <template #item="{ item, props }">
                                <v-list-item v-bind="props" :title="null" class="program-list-item">
                                    <div class="d-flex align-center w-100 gap-4">
                                        <font-awesome-icon :icon="['far', 'route']" class="flex-shrink-0 mr-4" />
                                        <div class="text-truncate">
                                            {{ item.title }}
                                        </div>
                                    </div>
                                </v-list-item>
                            </template>
                            <template #selection="{ item }">
                                <div class="d-flex align-center w-100 gap-4">
                                    <font-awesome-icon :icon="['far', 'route']" class="flex-shrink-0 mr-4" />
                                    <div class="text-truncate">
                                        {{ item.title }}
                                    </div>
                                </div>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-0">
                        <v-autocomplete
                            v-model="eoc.status"
                            label="Episode status"
                            :items="episodeOfCareStatusData"
                            item-title="display"
                            item-value="code"
                            data-cy="episodeStatus"
                            @update:model-value="handleStatusChange"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <!-- Show start date for planned or active status -->
                    <v-col v-if="eoc.status === 'active'" cols="6" class="py-0">
                        <BnDatePicker id="startDateTime" v-model="startDateTime" :input-date-format="authStore.inputDateFormat" label="Start date" with-time data-cy="startDateTime" />
                    </v-col>

                    <!-- Show estimated start date for waitlist -->
                    <v-col v-if="eoc.status === 'planned' || eoc.status === 'waitlist'" cols="6" class="py-0">
                        <BnDatePicker
                            id="eocEstStartDate"
                            v-model="eoc.eocEstStartDate"
                            :input-date-format="authStore.inputDateFormat"
                            label="Estimated start date"
                            @update:model-value="setEOCEstStartDate"
                        />
                    </v-col>
                </v-row>

                <!-- Show status reason for eligibility not determined and not eligible -->
                <v-row v-if="eoc.status === 'eligibility-not-determined' || eoc.status === 'not-eligible'">
                    <v-col cols="12" class="py-0">
                        <v-text-field v-model="eoc.statusReason" label="Status reason" @update:model-value="setStatusReason" />
                    </v-col>
                </v-row>
            </template>

            <BnListBox title="Phone" add data-cy="contactEditHumanPhoneList" @add="addPhone">
                <template #default>
                    <v-card v-if="!humanData.allPhones.length" flat class="d-flex text-caption pa-2" :border="false" @click="addPhone">
                        <div class="ml-auto">
                            Add phone
                            <font-awesome-icon :icon="['far', 'level-up']" class="ml-1 mr-3 mb-1" />
                        </div>
                    </v-card>
                    <template v-for="(phone, index) in humanData.allPhones.sort((a, b) => ((a.rank || 0) > (b.rank || 0) ? -1 : 1))" v-else :key="index">
                        <v-divider v-if="index"></v-divider>
                        <v-list-item density="compact" class="pr-0" data-cy="contactEditHumanPhoneItem" @click="editPhone(phone)">
                            <template #prepend>
                                <template v-if="phone.rank === 1">
                                    <font-awesome-icon :icon="['fas', 'star']" class="text-primary mr-2 align-self-center" />
                                </template>
                                <template v-else>
                                    <font-awesome-icon :icon="['far', phoneUseIcon[phone.use]]" class="mr-2 align-self-center" />
                                </template>
                            </template>
                            <v-list-item-subtitle>
                                <div class="bn-label">
                                    {{ phoneUseValues[phone.use] }}
                                </div>
                            </v-list-item-subtitle>
                            <v-list-item-title :class="{ usprivacy: getUSPrivacy(resource) }">{{ phone.value }}</v-list-item-title>
                            <div>
                                <v-chip v-if="phone.doNotContact" variant="outlined" size="x-small">
                                    <font-awesome-icon :icon="['far', 'ban']" />
                                    &nbsp; Do Not Contact
                                </v-chip>
                                <v-chip v-if="humanData.sendSms === phone.value" variant="outlined" size="x-small">
                                    <font-awesome-icon :icon="['far', 'comment-alt']" />
                                    &nbsp; Accepts SMS
                                </v-chip>
                            </div>
                            <template #append>
                                <BnTooltip v-if="phone.rank === 1 && humanData.allPhones.length > 1" bottom type="info">
                                    <v-btn class="d-flex align-center mr-2" icon variant="text" size="small" color="red" disabled>
                                        <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                    </v-btn>
                                    <template #message>
                                        <span>A preferred phone number must be designated.</span>
                                    </template>
                                </BnTooltip>
                                <v-btn v-else class="d-flex align-center mr-2" icon variant="text" size="small" color="red" @click.stop="removeContactPoint(phone)">
                                    <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                </v-btn>
                            </template>
                        </v-list-item>
                    </template>
                </template>
            </BnListBox>

            <BnListBox title="Email" add data-cy="contactEditHumanEmailList" @add="addEmail">
                <template #default>
                    <v-card v-if="!humanData.allEmails.length" flat class="d-flex text-caption pa-2" :border="false" @click="addEmail">
                        <div class="ml-auto">
                            Add email
                            <font-awesome-icon :icon="['far', 'level-up']" class="mx-1 mb-1" />
                        </div>
                    </v-card>
                    <template v-for="(email, index) in humanData.allEmails.sort((a, b) => ((a.rank || 0) > (b.rank || 0) ? -1 : 1))" v-else :key="index">
                        <v-divider v-if="index"></v-divider>
                        <v-list-item density="compact" class="pr-0" data-cy="contactEditHumanEmailItem" @click="editEmail(email)">
                            <template #prepend>
                                <div v-if="email.rank === 1" class="mr-2 align-self-center">
                                    <font-awesome-icon :icon="['fas', 'star']" class="text-primary" />
                                </div>
                                <div v-else class="mr-2 align-self-center">
                                    <font-awesome-icon v-if="email.use === 'work'" :icon="['far', emailUseIcon[email.use]]" />
                                    <font-awesome-icon v-else :icon="['far', 'at']" />
                                </div>
                            </template>
                            <v-list-item-subtitle>
                                <div class="bn-label">
                                    {{ emailUseTextValues[email.use] }}
                                </div>
                            </v-list-item-subtitle>
                            <v-list-item-title :class="{ usprivacy: getUSPrivacy(resource) }">{{ email.value }}</v-list-item-title>
                            <div>
                                <v-chip v-if="email.doNotContact" label text x-small>
                                    <font-awesome-icon :icon="['far', 'ban']" />
                                    &nbsp; Do Not Contact
                                </v-chip>
                            </div>
                            <template #append>
                                <v-btn class="d-flex mr-2" icon variant="text" size="small" color="red" @click.stop="removeContactPoint(email)">
                                    <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                </v-btn>
                            </template>
                        </v-list-item>
                    </template>
                </template>
            </BnListBox>

            <BnListBox title="Address" add="Add Address" data-cy="contactEditHumanAddressList" :required="v$.humanData.address?.required && !humanData.address.length" @add="addAddress">
                <template #default>
                    <v-card v-if="!humanData.address.length" flat class="d-flex text-caption pa-2" :border="false" @click="addAddress">
                        <div class="ml-auto">
                            Add address
                            <font-awesome-icon :icon="['far', 'level-up']" class="ml-1 mr-3 mb-1" />
                        </div>
                    </v-card>
                    <template v-for="(addressItem, index) in sortedAddress(humanData.address)" v-else :key="index">
                        <v-divider v-if="index"></v-divider>
                        <v-list-item density="compact" class="pr-0" data-cy="contactEditHumanAddressItem" @click="editAddress(addressItem)">
                            <template #prepend>
                                <div v-if="addressItem.preferred" class="mr-2 align-self-center">
                                    <font-awesome-icon :icon="['fas', 'star']" class="text-primary" />
                                </div>
                                <div v-else class="mr-2 align-self-center">
                                    <font-awesome-icon v-if="addressItem.use" :icon="['far', addressUseIcon[addressItem.use]]" />
                                </div>
                            </template>
                            <v-list-item-subtitle class="bn-label">{{ addressUseValues[addressItem.use] }}</v-list-item-subtitle>
                            <v-list-item-title :class="{ usprivacy: getUSPrivacy(resource) }"
                                >{{ addressItem.getAddressLine(0) }}<br />{{ addressItem.city + ' ' + addressItem.state + ' ' + addressItem.postalCode }} <br />
                                {{ addressItem.district ? addressItem.district : '' }}</v-list-item-title
                            >
                            <template #append>
                                <BnTooltip v-if="addressItem.preferred && humanData.address.length > 1" bottom type="info">
                                    <v-btn class="d-flex align-center mr-2" icon variant="text" size="small" color="red" disabled>
                                        <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                    </v-btn>
                                    <template #message>
                                        <span>A preferred address must be designated.</span>
                                    </template>
                                </BnTooltip>
                                <v-btn v-else class="d-flex align-center mr-2" icon variant="text" size="small" color="red" @click.stop="removeAddress(addressItem)">
                                    <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                </v-btn>
                            </template>
                        </v-list-item>
                    </template>
                </template>
            </BnListBox>

            <v-autocomplete
                v-if="isPatient"
                v-model="humanData.livingArrangement"
                label="Lacking permanent address"
                :items="livingArrangementOptions"
                return-object
                item-title="coding[0].display"
                clearable
                placeholder="No"
                persistent-placeholder
                data-cy="contactEditHumanLivingArrangement"
                @click:clear="humanData.livingArrangement = undefined"
            >
                <template #no-data>
                    <v-list-item>
                        <v-list-item-title> No option found </v-list-item-title>
                    </v-list-item>
                </template>
            </v-autocomplete>
            <v-autocomplete
                v-model="humanData.timeZone"
                label="Timezone"
                :items="timezoneData"
                item-title="timezone"
                item-value="timezone"
                clearable
                placeholder="Select timezone"
                data-cy="contactEditHumanTimeZone"
            >
                <template #no-data>
                    <v-list-item>
                        <v-list-item-title> Timezone not found </v-list-item-title>
                    </v-list-item>
                </template>
                <template #item="{ item, props }">
                    <v-list-subheader v-if="item.raw.header">
                        {{ item.raw.header }}
                    </v-list-subheader>
                    <v-divider v-else-if="item.raw.divider" />
                    <v-list-item v-else v-bind="props" />
                </template>
            </v-autocomplete>

            <template v-if="humanData.resourceType === 'Practitioner'">
                <!-- User Qualifications -->
                <BnListQualifications v-model="humanData.qualification" data-cy="contactEditHumanQualifications" @update:model-value="humanData.qualification = $event" />
                <!-- User Identifiers -->
                <BnListIdentifiers v-model="humanData.identifier" title="Practitioner identifiers" type="Practitioner" data-cy="contactEditHumanIdentifier" />
            </template>

            <template v-if="!isResourceUser(humanData)">
                <div class="bn-section-header">Gender</div>
                <div class="d-flex">
                    <v-select v-model="humanData.gender" label="Sex" :items="genderOptions" item-title="display" item-value="code" data-cy="contactEditHumanGender">
                        <template #append>
                            <BnInfo type="dialog" size="lg"> Current biological and physiological sex. </BnInfo>
                        </template>
                        <template #no-data>
                            <v-list-item>
                                <v-list-item-title> Sex not found </v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-select>
                </div>
            </template>
            <div class="d-flex">
                <v-select
                    v-if="humanData.resourceType === 'Patient'"
                    v-model="humanData.genderIdentity"
                    label="Gender identity"
                    :items="genderIdentityOptions"
                    item-title="coding[0].display"
                    return-object
                    data-cy="contactEditHumanGenderIdentity"
                >
                    <template #append>
                        <BnInfo size="lg" type="dialog"> The personal sense of being a man, woman, or other gender, regardless of the sex that person was assigned at birth. </BnInfo>
                    </template>
                </v-select>
            </div>
            <v-select
                v-if="!isResourceUser(humanData)"
                v-model="humanData.pronouns"
                label="Pronouns"
                :items="pronounOptions"
                item-title="display"
                return-object
                clearable
                data-cy="contactEditHumanPronouns"
                @click:clear="resetPronounSelect"
            ></v-select>
            <!-- <v-select label="Sexual Orientation" v-model="humanData.sexualOrientation" :items="sexualOrientationOptions" item-text="display" return-object clearable></v-select>-->

            <template v-if="humanData.resourceType === 'Patient'">
                <div class="bn-section-header">Race & Ethnicity</div>
                <v-select
                    ref="raceSelect"
                    v-model="humanData.raceCategories"
                    label="Race"
                    :items="raceCategoryOptions"
                    item-title="display"
                    return-object
                    multiple
                    small-chips
                    deletable-chips
                    clearable
                    data-cy="raceCategories"
                >
                    <template #prepend-item>
                        <div class="pa-2 text-caption d-flex justify-space-between">
                            Press the ESC key to close this list
                            <button class="mr-2" @click="closeMenu('raceSelect')">
                                <font-awesome-icon size="lg" :icon="['far', 'times']" class="mr-2" />
                            </button>
                        </div>
                    </template>
                </v-select>
                <v-autocomplete
                    v-if="!isWidget"
                    ref="raceDetailedSelect"
                    v-model="humanData.raceDetailed"
                    v-model:search="raceDetailedSearch"
                    label="Race detailed"
                    :loading="loadingRaceDetailedSearch"
                    :items="sortedRaceDetailedOptions"
                    item-title="display"
                    searchable
                    return-object
                    multiple
                    small-chips
                    deletable-chips
                    data-cy="raceDetailed"
                    clearable
                    @update:model-value="raceDetailedChangeHandler"
                >
                    <template #prepend-item>
                        <div class="pa-2 text-caption d-flex justify-space-between">
                            Press the ESC key to close this list
                            <button class="mr-2" @click="closeMenu('raceDetailedSelect')">
                                <font-awesome-icon size="lg" :icon="['far', 'times']" class="mr-2" />
                            </button>
                        </div>
                    </template>
                    <template #no-data>
                        <div class="pa-4 body-2">
                            {{ loadingRaceDetailedSearch ? 'Searching...' : 'Search for detailed race' }}
                        </div>
                    </template>
                </v-autocomplete>
                <v-autocomplete
                    v-model="humanData.ethnicityCategory"
                    label="Ethnicity"
                    :items="ethnicityCategoryOptions"
                    item-title="display"
                    searchable
                    return-object
                    small-chips
                    deletable-chips
                    data-cy="ethnicityCategory"
                    @update:update:model-value="ethnicityCategoryChangeHandler"
                >
                    <template #no-data>
                        <v-list-item>
                            <v-list-item-title> Ethnicity not found </v-list-item-title>
                        </v-list-item>
                    </template>
                </v-autocomplete>
                <v-autocomplete
                    v-if="!isWidget"
                    ref="ethinicityDetailedSelect"
                    v-model="humanData.ethnicityDetailed"
                    v-model:search-input="ethnicityDetailedSearch"
                    label="Ethnicity detailed"
                    :items="ethnicityDetailedOptions"
                    item-title="display"
                    searchable
                    return-object
                    multiple
                    small-chips
                    deletable-chips
                    clearable
                    data-cy="ethnicityDetailed"
                    @update:model-value="ethnicityDetailedChangeHandler"
                >
                    <template #prepend-item>
                        <div class="pa-2 text-caption d-flex justify-space-between">
                            Press the ESC key to close this list
                            <button class="mr-2" @click="closeMenu('ethinicityDetailedSelect')">
                                <font-awesome-icon size="lg" :icon="['far', 'times']" class="mr-2" />
                            </button>
                        </div>
                    </template>
                    <template #no-data>
                        <v-list-item>
                            <v-list-item-title> Ethnicity Details not found </v-list-item-title>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </template>

            <div class="bn-section-header">Additional demographics</div>
            <v-select
                v-if="humanData.resourceType === 'Patient'"
                v-model="humanData.maritalStatus"
                label="Marital status"
                :items="maritalStatusOptions"
                item-title="coding[0].display"
                return-object
                clearable
                data-cy="contactEditHumanMaritalStatus"
                @click:clear="humanData.maritalStatus = undefined"
            ></v-select>

            <v-select
                v-if="resource.resourceType === 'Practitioner'"
                v-model="humanData.spokenLanguage"
                label="Preferred language"
                :items="preferredLanguages"
                item-title="coding[0].display"
                return-object
                clearable
                data-cy="contactEditHumanSpokenLanguage"
            ></v-select>
            <v-select
                v-else
                v-model="humanData.spokenLanguage"
                label="Preferred language"
                :items="preferredLanguages"
                item-title="language.coding[0].display"
                return-object
                clearable
                data-cy="contactEditHumanPerferredLanguage"
                @click:clear="humanData.spokenLanguage = undefined"
            ></v-select>
            <v-text-field
                v-if="humanData.resourceType === 'Patient'"
                v-model="humanData.ssn"
                v-facade="'###-##-####'"
                label="Social security number"
                v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'ssn', true)"
                :class="{ usprivacy: getUSPrivacy(resource) }"
                data-cy="contactEditHumanSSN"
                @blur="v$.humanData.ssn.$touch()"
            >
                <template #append>
                    <BnInfo type="dialog" color="warning" size="lg">
                        Only collect a Social Security Number (SSN) if absolutely necessary. Many payers/providers actively purge SSNs from their systems and/or filter them from incoming data.
                    </BnInfo>
                </template>
            </v-text-field>
            <v-text-field v-if="humanData.resourceType === 'Patient'" v-model="humanData.driversLicense" label="Driver license number" class="usprivacy"></v-text-field>
            <v-select
                v-if="!isResourceUser(humanData)"
                v-model="humanData.religion"
                label="Religion"
                :items="religiousAffiliationOptions"
                item-title="coding[0].display"
                return-object
                clearable
                data-cy="contactEditHumanReligion"
                @click:clear="humanData.religion = undefined"
            ></v-select>
            <v-text-field v-if="isPatient" v-model="humanData.employerSchool" label="Employer and occupation or school" data-cy="contactEditHumanEmployerSchool"></v-text-field>
            <div>
                <v-textarea v-if="!isWidget" ref="keyComments" v-model="humanData.keyComments" label="Helpful comments" filled auto-grow rows="1" data-cy="keyCommentsTextarea" class="usprivacy">
                    <template #append>
                        <BnInfo v-if="isPatient" type="dialog" size="lg">
                            Use helpful comments to note any important, non-clinical, details to better serve this {{ authStore.clientVocab.toLowerCase() }}.
                        </BnInfo>
                    </template>
                </v-textarea>
            </div>
            <BnDialog ref="bnDialog" />

            <v-dialog v-if="showContactPointDialog" v-model="showContactPointDialog" scrollable persistent :fullscreen="$vuetify.display.xs" max-width="450px">
                <BnContactPointEdit
                    id="bn-contact-point-edit"
                    :mode="workingContactPoint.temp.mode"
                    :disable-preferred-check="workingContactPoint.temp.disablePreferred"
                    :resource="humanData"
                    :contact-point="workingContactPoint"
                    @cancel="showContactPointDialog = false"
                    @save="showContactPointDialog = false"
                ></BnContactPointEdit>
            </v-dialog>

            <v-dialog v-if="showAddressDialog" v-model="showAddressDialog" scrollable persistent :fullscreen="$vuetify.display.xs" max-width="450px">
                <BnContactAddress
                    id="bn-contact-address"
                    :mode="workingAddress.temp.mode"
                    :disable-preferred-check="workingAddress.temp.disablePreferred"
                    :resource="humanData"
                    :address="workingAddress"
                    @cancel="showAddressDialog = false"
                    @save="showAddressDialog = false"
                ></BnContactAddress>
            </v-dialog>
        </template>
    </DataProvider>
</template>

<style>
.program-select {
    width: 100%;
}

.program-list-item {
    min-width: 0;
    padding-right: 16px;
}

/* Ensure the input text doesn't overflow */
.program-select .v-field__input {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* Add padding for the warning icon when present */
.program-select .v-field--prepended {
    padding-left: 12px;
}

.v-card.v-theme--lightTheme.v-card--density-compact.v-card--variant-flat.no-select .d-flex .bn-section-header {
    margin-top: 8px;
}
.v-card.v-theme--lightTheme.v-card--density-compact.v-card--variant-flat.no-select .d-flex .bn-section-header .ml-4.d-flex {
    margin-bottom: -4px;
}
</style>
