import merge from 'lodash/merge';
import FHIRObjectBase from './FHIRObjectBase';
import DueX from './types/DueX';
import get from 'lodash/get';

export default class GoalTarget extends FHIRObjectBase {
    static __className = 'GoalTarget';

    __objectStructure = {
        due: DueX,
    };

    constructor(constructJson, className = 'GoalTarget') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        let dueXDueDate = get(constructJson, 'dueDate', undefined);
        if (dueXDueDate) {
            this.dueDate = dueXDueDate;
        }

        this.originalObjJson = this.toJSON();
    }

    get dueDate() {
        if (!this.due) {
            return undefined;
        }
        return this.due.dueDate;
    }
    set dueDate(val) {
        if (!this.due) {
            this.due = new DueX();
        }
        this.due.dueDate = val;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
