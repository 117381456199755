<script>
import userApi from '@/common/api/users.api.js';
import { $api } from '@/common/api';
import { required, sameAs, minLength, maxLength } from '@vuelidate/validators';
import BnAccountsList from '@/components/BnAccountsList.vue';
import BnToolbarBtn from '@/components/BnToolbarBtn.vue';
import BnButton from '@/components/BnButton.vue';

import { useAuthStore } from '@/stores/auth';
import { useVuelidate } from '@vuelidate/core';

export default {
    name: 'BnMyAccount',
    components: { BnButton, BnToolbarBtn, BnAccountsList },
    props: {
        fullName: {
            type: String,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            // dialog management props
            dialog: false,
            resolve: null,
            reject: null,
            options: null,
            baseOptions: {
                header: 'BestNotes',
                width: 450,
                persistent: true,
                info: undefined,
                warning: undefined,
            },
            props: {},

            currentPassword: null,
            newPassword: null,
            confirmNewPassword: null,
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmNewPassword: false,
            loading: false,
            updatePasswordMessage: null,
            updateMessageType: null,
            openMyAccount: this.open,
            myAccountUser: null,
            accounts: [],
        };
    },
    methods: {
        async show(options, props) {
            let res;
            if (this.isPortal) {
                res = await $api['accounts'].getPortal();
            } else {
                res = await $api['accounts'].get();
            }

            this.accounts = res.data['hydra:member'] || res.data;

            const authStore = useAuthStore();
            this.myAccountUser = authStore.user;

            this.options = { ...this.baseOptions, ...options };
            this.props = props;
            this.activeTab = 0;
            // display dialog
            this.dialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        close() {
            this.reset();
            this.resolve(false);
            this.dialog = false;
            this.options = undefined;
            this.props = {};
        },

        async updatePassword() {
            this.v$.$touch();
            let valid = await this.v$.$validate();
            if (!valid) return;

            let data = {
                currentPassword: this.currentPassword,
                newPassword: this.newPassword,
            };

            this.loading = true;
            this.updateMessageType = null;

            let updatePasswordResponse = this.isPortal ? await userApi.updatePortalPassword(data) : await userApi.updatePassword(data);

            if (!updatePasswordResponse?.data?.success) {
                this.updateMessageType = 'error';
                this.updatePasswordMessage = updatePasswordResponse?.data.errors[0] || 'Could not process request.';
                this.loading = false;
                return;
            }

            this.updateMessageType = 'success';
            this.updatePasswordMessage = 'Password updated successfully!';

            this.loading = false;

            this.reset();
        },

        reset() {
            this.currentPassword = null;
            this.newPassword = null;
            this.confirmNewPassword = null;
            this.v$.newPassword.$reset();
            this.v$.currentPassword.$reset();
            this.v$.confirmNewPassword.$reset();
        },
    },
    validations() {
        return {
            currentPassword: {
                required,
            },
            newPassword: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(4096),
            },
            confirmNewPassword: {
                required,
                sameAsPassword: sameAs(this.newPassword),
            },
        };
    },
};
</script>

<template>
    <v-dialog v-if="dialog" v-model="dialog" scrollable :fullscreen="$vuetify.display.xs" :width="options.width" :persistent="options.persistent">
        <v-card flat class="d-flex flex-column">
            <div class="flex-grow-0">
                <v-toolbar density="compact" flat>
                    <v-toolbar-title>Hello, {{ fullName }}</v-toolbar-title>
                    <BnToolbarBtn type="outlined" right color="primary" icon="times" label="Close" @click="close" />
                </v-toolbar>
                <v-divider />
            </div>
            <div class="pa-4 flex-grow-1 scroll-on">
                <div class="bn-section-header">Update password</div>
                <v-card flat class="pa-4" border="false">
                    <v-text-field
                        id="currentPassword"
                        v-model="currentPassword"
                        :type="showCurrentPassword ? 'text' : 'password'"
                        label="Current password"
                        v-bind="$bnVuelidateErrorExtractor(v$, 'currentPassword', true)"
                        :append-icon="showCurrentPassword ? '$eye' : '$eyeslash'"
                        @blur="v$.currentPassword.$touch()"
                        @click:append="showCurrentPassword = !showCurrentPassword"
                    ></v-text-field>
                    <v-text-field
                        id="newPassword"
                        v-model="newPassword"
                        :type="showNewPassword ? 'text' : 'password'"
                        label="New password"
                        v-bind="$bnVuelidateErrorExtractor(v$, 'newPassword', true)"
                        :append-icon="showNewPassword ? '$eye' : '$eyeslash'"
                        @blur="v$.newPassword.$touch()"
                        @click:append="showNewPassword = !showNewPassword"
                    ></v-text-field>
                    <v-text-field
                        id="confirmNewPassword"
                        v-model="confirmNewPassword"
                        :type="showConfirmNewPassword ? 'text' : 'password'"
                        label="Confirm new password"
                        v-bind="$bnVuelidateErrorExtractor(v$, 'confirmNewPassword', true)"
                        :append-icon="showConfirmNewPassword ? '$eye' : '$eyeslash'"
                        @blur="v$.confirmNewPassword.$touch()"
                        @click:append="showConfirmNewPassword = !showConfirmNewPassword"
                    ></v-text-field>
                    <div class="d-flex">
                        <BnButton color="primary" icon="check" outlined label="Update password" :disabled="!!v$.$errors.length" class="ml-auto" @click="updatePassword" />
                    </div>
                </v-card>
                <div class="text-button mt-4">Associated companies</div>
                <v-card border="false">
                    <BnAccountsList :accounts="accounts" read-only />
                </v-card>
            </div>
        </v-card>
    </v-dialog>
</template>
