<script>
import fileApi from '@/common/api/file.api';
import isEqual from 'lodash/isEqual';
import image from '@/assets/img/placeholder.png';

export default {
    name: 'BnImage',
    props: {
        imgSrcProp: { type: String },
        isPublic: {
            type: Boolean,
            default: false,
        },
        duration: {
            type: Number,
            default: 1440,
        },
        imgWidth: [Number, String],
        imgHeight: [Number, String],
        alt: String,
        placeholder: {
            type: Boolean,
            default: false,
        },
        hide: Boolean,
    },
    emits: ['load', 'invalidImage'],
    data() {
        return {
            imgSrcJpeg: this.placeholder ? image : null,
            imgSrcWebp: null,
        };
    },
    computed: {
        style() {
            let style = '';
            if (this.imgWidth) {
                style += 'width:' + this.imgWidth + 'px;';
            }
            if (this.imgHeight) {
                style += 'height:' + this.imgHeight + 'px;';
            }

            return style ? style : 'width:100%;height:100%;';
        },
        imageSize() {
            // large images are 150px.  So once you have an image greater than 100px.  It uses the large image, and so on
            let lgWidth = 120 / window.devicePixelRatio;
            let mdWidth = 60 / window.devicePixelRatio;

            if (this.imgWidth > lgWidth) {
                return 'lg';
            } else if (this.imgWidth > mdWidth) {
                return 'md';
            }

            return 'sm';
        },
    },
    watch: {
        imgSrcProp: {
            handler(newVal, oldVal) {
                if (isEqual(newVal, oldVal)) return;
                if (!this.isPublic) {
                    this.getSignedUrl();
                    return;
                }
                this.imgSrcJpeg = newVal + '.' + this.imageSize + '.jpeg';
                this.imgSrcWebp = newVal + '.' + this.imageSize + '.webp';
            },
            immediate: true,
        },
    },
    methods: {
        async getSignedUrl() {
            if (this.imgSrcProp && image !== this.imgSrcProp) {
                let signedUrls = await fileApi.getImageSignedUrl([this.imgSrcProp + '.' + this.imageSize + '.jpeg', this.imgSrcProp + '.' + this.imageSize + '.webp'], this.duration);
                if (signedUrls?.urls?.[0]) {
                    this.imgSrcJpeg = signedUrls.urls[0];
                    this.imgSrcWebp = signedUrls.urls[1];
                } else if (!signedUrls?.success) {
                    this.$emit('invalidImage');
                }
            }
        },
    },
};
</script>

<template>
    <picture :style="style" :class="{ 'd-flex': !hide, 'd-none': hide }">
        <source :srcset="imgSrcWebp" type="image/webp" />
        <img :src="imgSrcJpeg" :alt="alt" :style="style" @load="$emit('load')" />
    </picture>
</template>
