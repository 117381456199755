import { format } from 'date-fns-tz';
import { parseISO } from 'date-fns';

import { useAuthStore } from '@/stores/auth';

export const formatDate = function (value, dateFormat) {
    if (!value) return;
    value = value instanceof Date ? value.toISOString() : value;

    const authStore = useAuthStore();
    let dateFormatTemplate = dateFormat ? dateFormat : authStore.outputDateFormat;
    return format(parseISO(value), dateFormatTemplate);
};

export const formatTime = function (value, passedConfig = {}) {
    if (!value) return;
    const authStore = useAuthStore();
    let config = { ...{ showTZ: false, outputFormat: authStore.militaryTime ? 'kk:mm' : 'h:mm a' }, ...passedConfig };
    return formatDatetime(value, config);
};

export const formatDatetime = function (value, passedConfig = {}) {
    if (!value) return;
    const authStore = useAuthStore();
    let config = {
        showTZ: true,
        outputFormat: authStore.outputDateFormat + (authStore.militaryTime ? ' kk:mm' : ' h:mm a'),
        ...passedConfig,
    };

    // Remove the timezone from the output format if `removeTZ` is true
    if (config.showTZ && !config.removeTZ) {
        config.outputFormat += config.outputFormat.slice(-1) === 'z' ? '' : ' z';
    }

    value = value instanceof Date ? value.toISOString() : value;

    if (config.timezone) {
        return format(parseISO(value), config.outputFormat, { timeZone: config.timezone });
    }

    return format(parseISO(value), config.outputFormat);
};
