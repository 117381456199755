<script setup>
import { ref, computed } from 'vue';
import { sortBy } from 'lodash';

import BnAvatar from '@/components/BnAvatar';

const props = defineProps({
    accounts: Array,
    readOnly: Boolean,
});

const loading = ref(undefined);

const sortedAccounts = computed(() => {
    return sortBy(props.accounts, ['name']);
});

const avatarSize = computed(() => (props.readOnly ? 40 : 60));

const emit = defineEmits(['selectAccount']);

const selectAccount = (account) => {
    // prevent double-click from causing an error
    if (loading.value) {
        return;
    }
    loading.value = account.id;
    emit('selectAccount', account);
};

const windowHeight = computed(() => {
    return window.innerHeight;
});
</script>

<template>
    <v-container class="scroll-on" :style="'height: ' + (windowHeight - 175) + 'px'">
        <v-row>
            <v-col v-for="account in sortedAccounts" :key="account.id" cols="12" lg="4" md="6">
                <v-hover v-slot="{ hover }">
                    <v-card
                        ref="accountBtn"
                        :elevation="hover ? 2 : 0"
                        :class="{ 'on-hover': hover }"
                        :disabled="readOnly"
                        class="p-0 mx-auto"
                        width="100%"
                        max-width="600"
                        data-cy="accountListItem"
                        @click="selectAccount(account)"
                    >
                        <!-- Account name, address -->
                        <v-card-text class="pa-2 d-flex flex-column align-center">
                            <BnAvatar color="teal" :size="avatarSize" icon-size="xl" :resource="{ ...account, resourceType: 'Organization' }" no-badge class="ma-3" is-public />
                            <span class="text-h6">{{ account.name }}</span>
                            <v-chip v-if="$isBnSupportUser && account.userHasAccount" style="position: absolute; left: 8px" color="blue" text-color="white" small>User</v-chip>
                            <font-awesome-icon v-if="loading === account.id" style="position: absolute; right: 8px" class="fa-spin" size="xl" :icon="['far', 'spinner']" />
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </v-container>
</template>
