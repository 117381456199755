<script setup>
import { computed, useAttrs } from 'vue';
import { mergeProps } from 'vue';
import _ from 'lodash';
import { useDisplay } from 'vuetify';

const props = defineProps({
    label: String,
    icon: String,
    iconFamily: {
        type: String,
        default: 'far',
    },
    activator: Boolean,
    dense: Boolean,
    toolTip: String,
});

const emit = defineEmits(['click']);
const display = useDisplay();
const attrs = useAttrs();

const hasAttr = (attr) => {
    return attrs[attr] === '';
};

const buttonAttributes = computed(() => {
    let buttonAttrs = _.clone(attrs);

    if (!!props.icon && !hasAttr('outlined') && !hasAttr('text')) {
        buttonAttrs.icon = true;
        buttonAttrs.variant = 'plain';
    }
    buttonAttrs.color = buttonAttrs.color ? buttonAttrs.color : hasAttr('dark') ? 'white' : 'secondary';

    if (hasAttr('outlined')) {
        buttonAttrs.variant = 'outlined';
    } else if (hasAttr('text')) {
        buttonAttrs.variant = 'text';
    }

    if (props.dense && display.xs.value) {
        buttonAttrs.icon = true;
        buttonAttrs.size = 'large';
    }

    return buttonAttrs;
});

const click = () => {
    emit('click');
};
</script>

<template>
    <v-tooltip v-if="toolTip" bottom>
        <template #activator="{ props: tooltipProps }">
            <v-btn v-bind="mergeProps(tooltipProps, buttonAttributes)" @click.stop="click">
                <font-awesome-icon v-if="icon" :icon="[iconFamily, icon]" size="lg" />
                <span v-if="label && !display.xs.value" :class="{ 'ml-2': display.mdAndUp.value && icon, 'text-subtitle-2': hasAttr('fab') }" v-html="label"></span>
                <font-awesome-icon v-if="activator" :icon="['fas', 'caret-down']" size="lg" class="ml-3" />
            </v-btn>
        </template>
        <span v-html="toolTip"></span>
    </v-tooltip>
    <v-btn v-else v-bind="buttonAttributes" @click.stop="click">
        <font-awesome-icon v-if="icon" :icon="[iconFamily, icon]" size="lg" />
        <span v-if="label && !display.xs.value" :class="{ 'ml-2': icon, 'text-subtitle-2': hasAttr('fab') }" v-html="label"></span>
        <font-awesome-icon v-if="activator" :icon="['fas', 'caret-down']" size="lg" class="ml-3" />
    </v-btn>
</template>
