import { ref, onBeforeUnmount } from 'vue';
import { Capacitor } from '@capacitor/core';

const isNativePlatform = Capacitor.isNativePlatform();
const secondNavWidth = ref(isNativePlatform ? 260 : 300);
const keepDrawerOpen = ref(false);

export function useNavBar() {
    const setWidth = (width) => {
        secondNavWidth.value = width;
    };

    const keepNavOpen = (calendarNavDrawer) => {
        let openNavDrawer = isNativePlatform ? (calendarNavDrawer == 'smAndDown' ? false : true) : true;
        keepDrawerOpen.value = openNavDrawer;
    };

    onBeforeUnmount(() => {
        secondNavWidth.value = isNativePlatform ? 260 : 300; // set width to default
    });

    return { secondNavWidth, setWidth, keepNavOpen, keepDrawerOpen };
}
