// import { Organization, PractitionerRole, Reference } from '@/fhirworks';
// import { $httpFhirApi } from '@/common/api/httpFhir.service';
// import { SYSTEM_ADMIN_ROLE_ID } from '@/common/config';
// import { useAuthStore } from '@/stores/auth';
// import { useAccountFlow } from '@/common/workflowEngine/useAccountFlow';

// async function updateUser(user, name) {
//     const authStore = useAuthStore();
//     user.runOnce = name;
//     await $httpFhirApi.patch('/User/' + user.id + '?_method=json-patch', user.resourceJsonPatch());
//     authStore.user.user = user;
// }

export default async function run(to, user) {
    // make sure runOnce is set on user if it doesn't exist
    if (user && !user?.runOnce) user.runOnce = [];

    /**
     * On first login we want to ensure that a default brand is created and set up, So we call create_brand workflow
     * NOTE:  This should ONLY happen for the system administrator and ONLY if no brand exists yet.
     */
    // if (user && user.id !== 'bnsupport-user') {
    // }
}
