export const UserUpgradeStatements = [
    {
        toVersion: 1,
        statements: [
            `CREATE TABLE IF NOT EXISTS testData (
                 id INTEGER PRIMARY KEY NOT NULL,
                 name TEXT NOT NULL
                 );`,
        ],
    },
    /* add new statements below for next database version when required*/
    /*
    {
    toVersion: 2,
    statements: [
        `ALTER TABLE users ADD COLUMN email TEXT;`,
    ]
    },
    */
];
