import { useAuthStore } from '@/stores/auth';
import { createRouter, createWebHistory } from 'vue-router';
import isEmpty from 'lodash/isEmpty';
import run from '@/userFeatureNotification.js';
import { useNotificationStore } from '@/stores/notification';
// import NProgress from 'nprogress'; // disabled until we have a real use for this - Ticket #539

// Pages
import BasicLayout from '@/views/layouts/BasicLayout.vue';
import DefaultLayout from '@/views/layouts/DefaultLayout.vue';
import NotFound from '@/views/NotFoundPage';
import InvalidTab from '@/views/InvalidTabPage';

// routes
import authRoutes from '@/routes/auth.js';
import settings from '@/routes/settings.js';
import reports from '@/routes/reports.js';
import paymentReconciliation from '@/routes/paymentReconciliation.js';
import batchEligibility from '@/routes/batchEligibility.js';
import financialDashboard from './routes/financialDashboard.js';
import denialManagement from './routes/denialManagement.js';

export const baseRoutes = [
    {
        path: '/',
        name: 'home',
        component: DefaultLayout,
        redirect: { name: 'dashboard' },
        children: [
            {
                path: 'dashboard/:activeDialog?/:activeId?/:activeGroupId?',
                name: 'dashboard',
                component: () => import('@/views/DashboardPage.vue'),
                props: true,
            },
            {
                path: 'calendar/:activeDialog?/:activeId?/:activeGroupId?',
                name: 'calendar',
                component: () => import('@/views/CalendarPage.vue'),
            },
            {
                path: 'contacts/:activeMode?/:activeTab?/:activeSubTab?',
                name: 'contacts',
                component: () => import('@/views/ContactsPage.vue'),
            },
            {
                path: 'patient/:id/:activeTab?/:activeDialog?/:activeId?/:activeGroupId?',
                name: 'patient',
                component: () => import('@/views/HumanPage.vue'),
                props: true,
            },
            {
                path: 'practitioner/:id/:activeTab?/:activeDialog?/:activeId?/:activeGroupId?',
                name: 'practitioner',
                component: () => import('@/views/HumanPage.vue'),
                props: true,
            },
            {
                path: 'organization/:id/:activeTab?/:activeDialog?/:activeId?/:activeGroupId?',
                name: 'organization',
                component: () => import('@/views/HumanPage.vue'),
                props: true,
            },
            ...settings,
            ...reports,
            ...paymentReconciliation,
            ...batchEligibility,
            ...financialDashboard,
            ...denialManagement,
        ],
    },
    {
        path: '/invalid-tab',
        name: 'invalid-tab',
        meta: { authRequired: false },
        component: InvalidTab,
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        meta: { authRequired: false },
        component: NotFound,
    },
    ...authRoutes,
];

const basePortalRoutes = [
    {
        path: '/',
        name: 'home',
        component: DefaultLayout,
        redirect: { name: 'dashboard' },
        children: [
            {
                path: 'dashboard/:activeDialog?/:activeId?/:activeGroupId?',
                name: 'dashboard',
                component: () => import('@/views/PortalDashboardPage.vue'),
            },
            {
                path: 'calendar',
                name: 'calendar',
                component: () => import('@/views/CalendarPage.vue'),
            },
            {
                path: 'settings',
                redirect: { name: 'me' },
                name: 'settings',
                component: () => import('@/views/portal/PortalSettingsPage.vue'),
                children: [
                    {
                        path: 'me',
                        name: 'me',
                        component: () => import('@/views/settings/MePage.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '/applications',
        name: 'portal-applications',
        component: DefaultLayout,
    },
    {
        name: '404',
        path: '/not-found',
        meta: { authRequired: false },
        component: NotFound,
    },
    {
        path: '/:pathMatch(.*)*',
        meta: { authRequired: false },
        component: NotFound,
    },
];

const authPortalRoutes = [
    {
        path: '/auth',
        name: 'auth',
        component: BasicLayout,
        children: [
            {
                path: 'login',
                name: 'login',
                meta: { authRequired: false, isPortal: true },
                component: () => import('@/views/auth/LoginPage.vue'),
            },
            {
                path: 'logout',
                name: 'logout',
                meta: { authRequired: false },
                beforeEnter: async (to, from, next) => {
                    const authStore = useAuthStore();
                    await authStore.clearAuth();
                    next({ name: 'login', params: to.params, replace: true });
                },
            },
            {
                path: 'forgot-password',
                name: 'forgot-password',
                meta: { authRequired: false },
                component: () => import('@/views/auth/ForgotPasswordPage.vue'),
            },
            {
                path: 'lockout',
                name: 'lockout',
                meta: { authRequired: false },
                props: true,
                component: () => import('@/views/auth/LockoutPage.vue'),
                beforeEnter: async (to, from, next) => {
                    const authStore = useAuthStore();
                    // if being locked out from the account-selection page, log them out instead.
                    // OR: if no user or account log them out as well.
                    if (from.name === 'account-selection' || !authStore.account || !authStore.user) {
                        next({ name: 'login' });
                    }

                    await authStore.lockScreen(to.params.lockoutPage || from);
                    next();
                },
            },
            {
                path: 'reset-password',
                name: 'reset-password',
                meta: { authRequired: false },
                component: () => import('@/views/auth/ResetPasswordPage.vue'),
            },
            {
                path: 'accept-invitation',
                name: 'accept-invitation',
                meta: { authRequired: false, isPortal: true },
                props: true,
                component: () => import('@/views/auth/AcceptInvitationPage.vue'),
            },
            {
                path: 'confirm-email',
                name: 'confirm-email',
                meta: { authRequired: false },
                props: true,
                component: () => import('@/views/portal/auth/ConfirmEmailPage.vue'),
            },
            {
                path: 'account-selection',
                name: 'account-selection',
                meta: { isPortal: true },
                props: true,
                component: () => import('@/views/auth/AccountSelectionPage.vue'),
            },
        ],
    },
    {
        path: '/sites',
        name: 'sites',
        component: BasicLayout,
        children: [
            {
                path: ':site',
                name: 'sites-login',
                meta: { authRequired: false, isPortal: true },
                component: () => import('@/views/auth/LoginPage.vue'),
                props: true,
            },
        ],
    },
];

let baseUrl = import.meta.env.BASE_URL;
let routes;
if (window.page === 'ehr') {
    routes = baseRoutes;
} else {
    routes = basePortalRoutes.concat(authPortalRoutes);

    if (import.meta.env.DEV) baseUrl += 'portal';
}

export async function beforeEach(to, from, next) {
    const authStore = useAuthStore();
    // Try to refresh credentials in case of page refresh
    if (!authStore.isAuthenticated) {
        await authStore.refreshCredentials();
    }

    // let them logout
    if (to.name === 'logout') {
        return next({ name: 'login' });
    }

    // If user is locked out only let them navigate to the lockout screen, or let them logout.
    if (authStore.isLockedOut && !['lockout', 'login'].includes(to.name)) {
        return next({ name: 'lockout' });
    }

    // Allow navigation to pages that do not require authentication
    if (to.meta.authRequired === false) {
        return next();
    }

    // If we're not authenticated and this page requires authentication redirect to login page
    if (!authStore.isAuthenticated && to.meta.authRequired !== false) {
        return next({ name: 'login' });
    }

    // If we're authenticated and going to dev management let them in
    if (authStore.isAuthenticated && isEmpty(authStore.account) && to.name === 'dev-management') {
        return next();
    }

    // If we're authenticated but no account is selected then redirect to the account selection page
    if (authStore.isAuthenticated && isEmpty(authStore.account) && to.name !== 'account-selection') {
        return next({ name: 'account-selection' });
    }

    // check that user has proper permissions
    if (to.meta.hasPermission && !authStore.hasPermission(to.meta.hasPermission)) {
        return next(false);
    }

    if (to.meta.hasPermissions && !authStore.hasPermissions(to.meta.hasPermissions)) {
        return next(false);
    }

    // only allow portal users with no aidbox user to access applications page (until they are accepted)
    // let noUserRoutes = ['account-selection', 'portal-applications'];
    // if (store.state.isPortal && authStore.noAidboxUser && !noUserRoutes.includes(to.name)) {
    //     return next({ name: 'portal-applications' });
    // }

    //Check for the meta tag for showing the progress bar.
    if (to.meta.showProgressBar !== false) {
        // NProgress.start(); // TODO: Make a service that keeps count of the start and done calls and then prevent calling done prematurely
    }
    next();
}

let router = createRouter({
    history: createWebHistory(baseUrl),
    linkActiveClass: 'open active',
    routes: routes,
});

router.beforeEach(async (to, from, next) => beforeEach(to, from, next));

router.afterEach((to) => {
    // NProgress.done();

    // run first time route stuff
    const authStore = useAuthStore();
    run(to, authStore.user.user);
});

window.onload = function () {
    let reloading = sessionStorage.getItem('reloadNotification');
    if (reloading) {
        sessionStorage.removeItem('reloadNotification');
        useNotificationStore().add({
            message: 'An update from the server has been loaded.  Please try again.',
            timeout: 5000,
        });
    }
};

router.onError((error) => {
    if (/loading chunk .* failed|Failed to fetch dynamically imported module/i.test(error.message)) {
        sessionStorage.setItem('reloadNotification', true);
        document.location.reload();
    }
});
export default router;
