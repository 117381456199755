<script>
import dataProvider from '@/components/DataProvider.js';
import { required } from '@vuelidate/validators';
import { Address, ContactPoint, OrganizationContact } from '@/fhirworks';
import BnContactAddress from '@/components/contacts/BnContactAddress.vue';
import BnContactPointEdit from '@/components/contacts/BnContactPointEdit.vue';
import BnFormContact from '@/components/forms/BnFormContact.vue';
import BnImageUploader from '@/components/BnImageUploader.vue';
import BnListBox from '@/components/BnListBox.vue';
import BnListIdentifiers from '@/components/lists/BnListIdentifiers.vue';
import Attachment from '@/fhirworks/types/Attachment';
import BnRelated from '@/components/contacts/BnRelated.vue';
import { useVuelidate } from '@vuelidate/core';
import BnTooltip from '@/components/BnTooltip';

export default {
    name: 'BnContactEditOrganization',
    components: {
        BnTooltip,
        BnRelated,
        BnListIdentifiers,
        BnFormContact,
        BnContactAddress,
        BnContactPointEdit,
        BnListBox,
        BnImageUploader,
        DataProvider: dataProvider,
    },
    props: {
        resource: Object,
        relationshipProps: Object,
        authStore: Object,
    },
    emits: ['validate'],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            dpOrganization: null,
            phoneUseIcon: {
                work: 'phone-office',
                mobile: 'mobile',
            },
            phoneUseValues: {
                work: 'Work',
                mobile: 'Mobile',
            },
            emailUseIcon: {
                home: 'at',
                work: 'briefcase',
            },
            emailUseTextValues: {
                home: 'Personal',
                work: 'Work',
            },
            addressUseIcon: {
                home: 'home',
                work: 'building',
                billing: 'mailbox',
            },
            addressUseValues: {
                home: 'Home',
                work: 'Work',
                billing: 'Mailing',
            },
            workingContactPoint: null,
            showContactPointDialog: false,
            workingAddress: null,
            showAddressDialog: false,
            showContactDialog: false,
            workingContact: null,
        };
    },
    validations() {
        return {
            dpOrganization: {
                name: {
                    required,
                },
            },
        };
    },
    computed: {
        adminContacts() {
            let adminContact = this.dpOrganization.contact.find((contact) => contact.purpose.coding[0]?.code == 'ADMIN');
            return adminContact ? [adminContact] : [];
        },
        billingContacts() {
            let billingcontact = this.dpOrganization.contact.find((contact) => contact.purpose.coding[0]?.code == 'BILL');
            return billingcontact ? [billingcontact] : [];
        },
        isAccount() {
            return this.dpOrganization.id === this.authStore.account.id;
        },
        isAgency() {
            return !this.isAccount && this.dpOrganization.partOf?.id === this.authStore.account.id && this.dpOrganization.partOf?.resourceType === 'Organization';
        },
        editOrgLabel() {
            return this.isAgency ? 'Agency name' : 'Organization name';
        },
        photo: {
            get() {
                return this.dpOrganization?.photo?.url;
            },
            set(val) {
                this.dpOrganization.photo = new Attachment({ url: val });
            },
        },
    },
    watch: {
        dpOrganization: {
            async handler() {
                let valid = await this.v$.dpOrganization?.$validate();
                if (!valid) {
                    this.v$.dpOrganization?.$touch();
                }
                this.$emit('validate', !valid);
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        if (this.relationshipProps?.scrollToControlRef) {
            this.$nextTick(() => {
                this.$refs[this.relationshipProps.scrollToControlRef].$el.scrollIntoView({ behavior: 'smooth' });
                // setTimeout used because it was being ignored because another field was receiving focus
                setTimeout(() => this.$refs[this.relationshipProps.scrollToControlRef].focus(), 150);
            });
        }
    },
    methods: {
        addPhone(phoneOption) {
            if (typeof phoneOption !== 'string') phoneOption = 'work';
            let disablePreferredCheckBox = false;
            let contactJson = { system: 'phone', use: phoneOption };
            if (phoneOption === 'fax') {
                contactJson = { system: 'fax', use: 'work' };
            }
            if (!this.resource.allPhones.length) {
                contactJson.rank = 1;
                disablePreferredCheckBox = true;
            }

            this.workingContactPoint = new ContactPoint(contactJson);
            this.workingContactPoint.temp.mode = 'add';
            this.workingContactPoint.temp.disablePreferred = disablePreferredCheckBox;
            this.showContactPointDialog = true;
        },
        editPhone(phone) {
            this.workingContactPoint = phone;
            this.workingContactPoint.temp.mode = 'edit';
            this.workingContactPoint.temp.disablePreferred = false;
            if (this.workingContactPoint.rank === 1 && this.resource.allPhones.length > 1) {
                this.workingContactPoint.temp.disablePreferred = true;
            }
            this.showContactPointDialog = true;
        },
        addEmail() {
            let disablePreferredCheckBox = false;
            let emailJson = { system: 'email', use: 'work' };
            if (!this.humanData?.allEmails?.length) {
                emailJson.rank = 1;
                disablePreferredCheckBox = true;
            }
            this.workingContactPoint = new ContactPoint(emailJson);
            this.workingContactPoint.temp.mode = 'add';
            this.workingContactPoint.temp.disablePreferred = disablePreferredCheckBox;
            this.showContactPointDialog = true;
        },
        editEmail(email) {
            this.workingContactPoint = email;
            this.workingContactPoint.temp.mode = 'edit';
            this.showContactPointDialog = true;
        },
        removeContactPoint(contactPoint) {
            let contactIndex = this.dpOrganization.telecom.indexOf(contactPoint);
            if (contactIndex >= 0) {
                this.dpOrganization.telecom.splice(contactIndex, 1);
            }
        },
        addAddress(addressOption) {
            let disablePreferredCheckBox = false;
            if (typeof addressOption !== 'string') addressOption = 'work';
            this.workingAddress = new Address({ use: addressOption });
            if (!this.resource.address.length) {
                this.workingAddress.preferred = true;
                disablePreferredCheckBox = true;
            }
            this.workingAddress.temp.mode = 'add';
            this.workingAddress.temp.disablePreferred = disablePreferredCheckBox;
            this.showAddressDialog = true;
        },
        editAddress(address) {
            this.workingAddress = address;
            this.workingAddress.temp.mode = 'edit';
            this.workingAddress.temp.disablePreferred = false;
            if (this.workingAddress.preferred && this.resource.address.length) {
                this.workingAddress.temp.disablePreferred = true;
            }
            this.showAddressDialog = true;
        },
        removeAddress(addressOption) {
            let addressIndex = this.dpOrganization.address.indexOf(addressOption);
            if (addressIndex >= 0) {
                this.dpOrganization.address.splice(addressIndex, 1);
            }
        },
        sortedAddress(addresses) {
            let addressSortOrder = {
                home: 1,
                mailing: 2,
                billing: 2,
                work: 3,
            };
            return addresses.map((address) => address).sort((a, b) => (a.preferred ? 0 : 1) - (b.preferred ? 0 : 1) || (addressSortOrder[a.use] > addressSortOrder[b.use] ? 1 : -1));
        },
        addContact(contactType) {
            let newContact = new OrganizationContact({
                purpose: {
                    coding: [
                        {
                            code: contactType,
                        },
                    ],
                },
            });
            newContact.temp.mode = 'add';
            this.workingContact = newContact;
            this.showContactDialog = true;
        },
        editContact(contact) {
            this.workingContact = contact;
            this.showContactDialog = true;
        },
        cancelContact() {
            this.workingContact = null;
            this.showContactDialog = false;
        },
        saveContact(contact) {
            // temp.mode is set and the whole object is passed but since
            // getObjectCopy is used the local copy has be used
            if (this.workingContact.temp.mode === 'add') {
                this.dpOrganization.contact = contact;
            } else {
                this.workingContact.setPropertyValues(contact.toJSON());
            }
            this.workingContact = null;
            this.showContactDialog = false;
        },
        removeContact(contact) {
            let contactIndex = this.dpOrganization.contact.indexOf(contact);
            if (contactIndex >= 0) {
                this.dpOrganization.contact.splice(contactIndex, 1);
            }
        },
        async saveRelationship(newContact) {
            return await this.$refs.contactRelationship.saveNewContactRelationship(newContact);
        },
        isOrganization() {
            return this.dpOrganization?.type?.[0]?.coding?.[0]?.code === 'pay';
        },
    },
};
</script>

<template>
    <DataProvider v-model="dpOrganization" :resource="resource" :validator="v$.dpOrganization">
        <template #default>
            <div>
                <div class="d-flex">
                    <div class="flex-grow-1 mr-4">
                        <v-text-field
                            v-model="dpOrganization.name"
                            :label="editOrgLabel"
                            autofocus
                            v-bind="$bnVuelidateErrorExtractor(v$.dpOrganization, 'name', true)"
                            data-cy="organizationName"
                            @blur="v$.dpOrganization.name.$touch()"
                        />
                        <v-text-field v-model="dpOrganization.workUrlValue" label="Website"></v-text-field>
                    </div>
                    <BnImageUploader ref="dpOrganization" v-model="photo" is-public :resource="dpOrganization" avatar :options="{ size: 110 }" vocab="Organization" placeholder />
                </div>

                <BnRelated
                    v-if="relationshipProps && relationshipProps.relationSubject && relationshipProps.relationSubject.id"
                    ref="contactRelationship"
                    :subject-id="relationshipProps.relationSubject.id"
                    :subject-resource-type="relationshipProps.relationSubject.resourceType"
                    :adding-new-contact="true"
                    :new-contact-data="dpOrganization"
                ></BnRelated>

                <BnListBox title="Phone" add="Add phone" data-cy="organizationEditPhoneList" @add="addPhone">
                    <template #default>
                        <v-card v-if="!dpOrganization.allPhones.length" variant="flat" class="d-flex text-caption pa-2" :border="false" @click="addPhone">
                            <div class="ml-auto">Add phone <font-awesome-icon :icon="['far', 'level-up']" class="mx-2" /></div>
                        </v-card>
                        <template v-for="(phone, index) in dpOrganization.allPhonesWithFax.sort((a, b) => ((a.rank || 0) > (b.rank || 0) ? -1 : 1))" v-else :key="index">
                            <v-divider v-if="index"></v-divider>
                            <v-list-item density="compact" class="pr-0" @click="editPhone(phone)">
                                <template #prepend>
                                    <div class="mr-4 align-self-center">
                                        <font-awesome-icon v-if="phone.rank === 1" :icon="['fas', 'star']" class="mr-2 text-primary" />
                                        <font-awesome-icon :icon="['far', phoneUseIcon[phone.use]]" />
                                    </div>
                                </template>
                                <div>
                                    <v-list-item-subtitle>
                                        <div class="bn-label">
                                            {{ phone.system === 'fax' ? 'Fax' : phoneUseValues[phone.use] }}
                                        </div>
                                    </v-list-item-subtitle>
                                    <v-list-item-title>{{ phone.value }}</v-list-item-title>
                                </div>
                                <div>
                                    <v-chip v-if="phone.doNotContact" label text x-small>
                                        <font-awesome-icon :icon="['far', 'ban']" />
                                        &nbsp; Do not contact
                                    </v-chip>
                                    <v-chip v-if="dpOrganization.sendSms === phone.value" label text x-small>
                                        <font-awesome-icon :icon="['far', 'comment-alt']" />
                                        &nbsp; Accepts SMS
                                    </v-chip>
                                </div>
                                <template #append>
                                    <div>
                                        <BnTooltip v-if="phone.rank === 1 && resource.allPhones.length > 1" bottom type="info">
                                            <v-btn class="d-flex align-center mt-n2" icon variant="text" size="small" color="red" disabled>
                                                <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                            </v-btn>
                                            <template #message>
                                                <span>A preferred phone number must be designated.</span>
                                            </template>
                                        </BnTooltip>
                                        <v-btn v-else class="mr-4" size="small" icon variant="text" color="red" @click.stop="removeContactPoint(phone)">
                                            <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                        </v-btn>
                                    </div>
                                </template>
                            </v-list-item>
                        </template>
                    </template>
                </BnListBox>

                <BnListBox title="Email" add="Add email" @add="addEmail">
                    <template #default>
                        <v-card v-if="!dpOrganization.allEmails.length" variant="flat" class="d-flex text-caption pa-2" :border="false" @click="addEmail">
                            <div class="ml-auto">
                                Add email
                                <font-awesome-icon :icon="['far', 'level-up']" class="mx-1 mb-1" />
                            </div>
                        </v-card>
                        <template v-for="(email, index) in dpOrganization.allEmails.sort((a, b) => ((a.rank || 0) > (b.rank || 0) ? -1 : 1))" v-else :key="index">
                            <v-divider v-if="index"></v-divider>
                            <v-list-item density="compact" class="pr-0" @click="editEmail(email)">
                                <template #prepend>
                                    <div class="mr-4 align-self-center">
                                        <font-awesome-icon v-if="email.rank === 1" :icon="['fas', 'star']" class="mr-2 text-primary" />
                                        <font-awesome-icon v-if="email.use === 'work'" :icon="['far', emailUseIcon[email.use]]" />
                                        <font-awesome-icon v-else :icon="['far', 'at']" />
                                    </div>
                                </template>
                                <div>
                                    <v-list-item-subtitle>
                                        <div class="bn-label">
                                            {{ emailUseTextValues[email.use] }}
                                        </div>
                                    </v-list-item-subtitle>
                                    <v-list-item-title>{{ email.value }}</v-list-item-title>
                                </div>
                                <div>
                                    <v-chip v-if="email.doNotContact" label variant="text" x-small>
                                        <font-awesome-icon :icon="['far', 'ban']" />
                                        &nbsp; Do Not Contact
                                    </v-chip>
                                </div>
                                <template #append>
                                    <v-btn icon class="mr-4" size="small" variant="text" color="red" @click.stop="removeContactPoint(email)">
                                        <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                    </v-btn>
                                </template>
                            </v-list-item>
                        </template>
                    </template>
                </BnListBox>

                <BnListBox title="Address" add="Add address" data-cy="organizationAddress" @add="addAddress">
                    <template #default>
                        <v-card v-if="!dpOrganization.address.length" variant="flat" class="d-flex text-caption pa-2" :border="false" @click="addAddress">
                            <div class="ml-auto">
                                Add address
                                <font-awesome-icon :icon="['far', 'level-up']" class="mx-1 mb-1" />
                            </div>
                        </v-card>
                        <template v-for="(addressItem, index) in sortedAddress(dpOrganization.address)" v-else :key="index">
                            <v-divider v-if="index"></v-divider>
                            <v-list-item class="pr-0" @click="editAddress(addressItem)">
                                <template #prepend>
                                    <div class="mr-4 align-self-center">
                                        <font-awesome-icon v-if="addressItem.preferred" :icon="['fas', 'star']" class="mr-2 text-primary" />
                                        <font-awesome-icon v-if="addressItem.use" :icon="['far', addressUseIcon[addressItem.use]]" />
                                    </div>
                                </template>
                                <div>
                                    <v-list-item-subtitle>
                                        <div class="bn-label">
                                            {{ addressUseValues[addressItem.use] }}
                                        </div>
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{ addressItem.getAddressLine(0) }}<br />{{ addressItem.city + ' ' + addressItem.state + ' ' + addressItem.postalCode }} <br />
                                        {{ addressItem.district ? addressItem.district : '' }}</v-list-item-title
                                    >
                                </div>
                                <template #append>
                                    <div v-if="addressItem.preferred && resource.address?.length > 1" class="my-2 align-self-center">
                                        <BnTooltip bottom type="info">
                                            <v-btn class="d-flex align-center mt-n2" icon variant="text" size="small" color="red" disabled>
                                                <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                            </v-btn>
                                            <template #message>
                                                <span>A preferred address must be designated.</span>
                                            </template>
                                        </BnTooltip>
                                    </div>
                                    <v-btn v-else icon class="mr-4" size="small" variant="text" color="red" @click.stop="removeAddress(addressItem)">
                                        <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                    </v-btn>
                                </template>
                            </v-list-item>
                        </template>
                    </template>
                </BnListBox>

                <BnListBox
                    title="Primary contact"
                    :items="adminContacts"
                    item-value="id"
                    :item-text="{ title: 'fullName', subtitle: 'primaryPhoneValue' }"
                    item-icon="user"
                    :add="adminContacts.length ? undefined : 'Add Primary Contact'"
                    add-icon="plus"
                    remove
                    clickable
                    data-cy="organizationPrimaryContact"
                    @add="addContact('ADMIN')"
                    @remove="removeContact(adminContacts[0])"
                    @click="editContact(adminContacts[0])"
                >
                </BnListBox>

                <BnListBox
                    v-if="isAccount || isAgency"
                    title="Financial/Billing contact"
                    :items="billingContacts"
                    item-value="id"
                    :item-text="{ title: 'fullName' }"
                    item-icon="user"
                    :add="billingContacts.length ? undefined : 'Add Financial/Billing Contact'"
                    add-icon="plus"
                    remove
                    clickable
                    data-cy="organizationBillingContact"
                    @add="addContact('BILL')"
                    @remove="removeContact(billingContacts[0])"
                    @click="editContact(billingContacts[0])"
                >
                </BnListBox>

                <BnListIdentifiers v-if="isAgency" v-model="dpOrganization.identifier" type="Organization" class="pb-0" title="Practice identifiers" />

                <v-textarea
                    v-if="isOrganization()"
                    ref="keyComments"
                    v-model="dpOrganization.keyComments"
                    filled
                    label="Helpful comments"
                    auto-grow
                    rows="1"
                    data-cy="keyCommentsTextarea"
                ></v-textarea>

                <v-dialog v-if="showContactPointDialog" v-model="showContactPointDialog" scrollable persistent :fullscreen="$vuetify.display.xs" max-width="400px">
                    <BnContactPointEdit
                        :mode="workingContactPoint.temp.mode"
                        :disable-preferred-check="workingContactPoint.temp.disablePreferred"
                        :resource="dpOrganization"
                        :contact-point="workingContactPoint"
                        :phone-use-options="[
                            { value: 'work', text: 'Work' },
                            { value: 'fax', text: 'Fax' },
                            { value: 'mobile', text: 'Mobile' },
                        ]"
                        @cancel="showContactPointDialog = false"
                        @save="showContactPointDialog = false"
                    ></BnContactPointEdit>
                </v-dialog>

                <v-dialog v-if="showAddressDialog" v-model="showAddressDialog" scrollable persistent :fullscreen="$vuetify.display.xs" max-width="400px">
                    <BnContactAddress
                        :mode="workingAddress.temp.mode"
                        :disable-preferred-check="workingAddress.temp.disablePreferred"
                        :resource="dpOrganization"
                        :address="workingAddress"
                        :address-use-options="[
                            { value: 'work', text: 'Work' },
                            { value: 'billing', text: 'Mailing' },
                        ]"
                        @cancel="showAddressDialog = false"
                        @save="showAddressDialog = false"
                    ></BnContactAddress>
                </v-dialog>

                <v-dialog v-if="showContactDialog" v-model="showContactDialog" scrollable persistent :fullscreen="$vuetify.display.xs" max-width="400px">
                    <BnFormContact :contact="workingContact" :mode="workingContact.temp.mode" @cancel="cancelContact" @save="saveContact"> </BnFormContact>
                </v-dialog>
            </div>
        </template>
    </DataProvider>
</template>
