<script>
import _ from 'lodash';

export default {
    name: 'BnToolbarBtn',
    props: {
        type: {
            type: String,
            default: 'outlined',
        },
        label: String,
        forceLabel: Boolean,
        icon: String,
        color: {
            type: String,
            default: 'secondary',
        },
        kitIcon: String,
        loading: Boolean,
    },
    emits: ['click'],
    computed: {
        buttonAttributes() {
            let attrs = _.clone(this.$attrs);
            if (this.type === 'outlined' && (this.$vuetify.display.smAndUp || this.forceLabel)) {
                attrs.variant = 'outlined';
            } else if (this.type === 'text' && (this.$vuetify.display.smAndUp || this.forceLabel)) {
                attrs.variant = 'text';
            } else if (this.type === 'icon' || (this.$vuetify.display.xs && !this.forceLabel)) {
                attrs.icon = true;
                attrs.class = 'rounded-circle';
            }

            if (this.loading) {
                attrs.disabled = true;
            }

            return attrs;
        },
    },
    methods: {
        click() {
            if (this.loading) return;

            this.$emit('click');
        },
    },
};
</script>

<template>
    <v-btn v-bind="buttonAttributes" :kit-icon="kitIcon" :color="color" :data-cy="$attrs['data-cy']" @click.stop="click">
        <font-awesome-icon v-if="loading" :icon="['far', 'spinner']" size="lg" class="fa-spin" />
        <i v-else-if="kitIcon" :class="'mr-1 fa-kit ' + kitIcon" />
        <font-awesome-icon v-else-if="icon" :icon="['far', icon]" size="lg" :class="{ 'fa-spin': icon === 'spinner' }" />
        <span v-if="$vuetify.display.smAndUp || forceLabel" :class="{ 'ml-2': label && icon }" style="margin-top: 2px" v-html="label"></span>
    </v-btn>
</template>
