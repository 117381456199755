import { $http } from '@/common/api/http.service';
import LogEntry, { LOG_CRITICAL, LOG_DEBUG, LOG_ERROR, LOG_INFO, LOG_NOTICE, LOG_WARNING } from '@/common/log/log';
import { basePath } from '@/common/api/index';
import { useAuthStore } from '@/stores/auth';
import { useLogStore } from '@/stores/log';
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable no-console */

export class LogService {
    log(level, summary, description = null, trace = null) {
        const authStore = useAuthStore();
        const logStore = useLogStore();

        let logId = uuidv4();
        const entry = new LogEntry(level, summary, description, trace, null, logId);

        if (authStore.account.id) {
            switch (level) {
                case LOG_CRITICAL:
                case LOG_ERROR:
                    console.error('logId: ' + logId + '\n' + summary, entry);
                    break;
                case LOG_WARNING:
                    console.warn('logId: ' + logId + '\n' + summary, entry);
                    break;
                default:
                    console.log('logId: ' + logId + '\n' + summary, entry);
                    break;
            }

            return $http
                .post(
                    basePath('account') + '/log',
                    { ...entry, trace: entry.summary + '\n' + entry.trace, occurred: entry.occurred.toISOString(), referrer: window.location.href },
                    { errorHandle: false },
                )
                .then((response) => {
                    return response;
                })
                .catch(() => {
                    //defer the failure so we can try again later.
                    logStore.deferLogEntry(entry);
                });
        } else {
            //defer the log entries until we have a valid account.
            logStore.deferLogEntry(entry);
        }
    }

    critical(summary, description = null, trace = null) {
        return this.log(LOG_CRITICAL, summary, description, trace);
    }

    error(summary, description = null, trace = null) {
        return this.log(LOG_ERROR, summary, description, trace);
    }

    warning(summary, description = null, trace = null) {
        return this.log(LOG_WARNING, summary, description, trace);
    }

    notice(summary, description = null, trace = null) {
        return this.log(LOG_NOTICE, summary, description, trace);
    }

    info(summary, description = null, trace = null) {
        return this.log(LOG_INFO, summary, description, trace);
    }

    debug(summary, description = null, trace = null) {
        return this.log(LOG_DEBUG, summary, description, trace);
    }
}

const $log = new LogService();

export { $log };
