<script>
import { VAvatar, VListItem } from 'vuetify/components';
import { getContactType } from '@/common/core';
import { contactIcon, contactColor } from '@/filters/iconFilters';

export default {
    name: 'BnResourceIcon',
    components: {
        VAvatar,
        VListItem,
    },
    props: {
        resource: {
            type: [String, Object],
            default: undefined,
        },
        size: {
            type: [String, Number],
            default: 28,
        },
        iconSize: {
            type: String,
            default: 'lg',
        },
        component: {
            type: String,
            default: 'v-avatar',
        },
        disabled: Boolean,
    },
    computed: {
        resourceType() {
            if (typeof this.resource === 'object') {
                return getContactType(this.resource);
            } else {
                return this.resource;
            }
        },
        style() {
            let style = '';
            if (this.disabled) {
                style += 'opacity: 0.3 !important;';
            }
            return style;
        },
    },
    methods: {
        contactIcon,
        contactColor,
    },
};
</script>

<template>
    <component :is="component" :size="size" class="justify-center" :color="contactColor(resourceType)" :style="style">
        <div class="text-white">
            <font-awesome-icon :size="iconSize" :icon="contactIcon(resourceType)" />
        </div>
    </component>
</template>
