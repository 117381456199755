<script>
export default {
    name: 'BnInfo',
    inheritAttrs: false,
    props: {
        type: {
            type: String,
            default: 'snackbar',
        },
        color: {
            type: String,
            default: 'info',
        },
        header: {
            type: String,
            default: 'Info',
        },
        icon: {
            type: String,
            default: 'info-circle',
        },
        size: {
            type: String,
            default: 'small',
        },
        iconSize: {
            type: String,
            default: 'lg',
        },
        width: {
            type: String,
            default: '450px',
        },
        label: String,
        message: String,
        educate: Boolean,
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        buttonIcon() {
            let icon;
            switch (this.icon) {
                case 'info':
                    icon = 'info-circle';
                    break;
                case 'warning':
                case 'error':
                    icon = 'exclamation-circle';
                    break;
                case 'educate':
                    icon = 'university';
                    break;
                default:
                    icon = this.icon;
            }
            return this.educate ? 'university' : icon;
        },
        headerIcon() {
            return this.educate ? 'user-graduate' : this.buttonIcon;
        },
        headerText() {
            return this.educate ? 'BestNotes 101' : this.header;
        },
    },
};
</script>

<template>
    <!-- Add bn-info to a field label slot -->
    <div v-if="type === 'slotLabel'" class="d-flex">
        {{ label }}
        <bn-info v-bind="$attrs" :icon-size="iconSize" class="align-baseline" type="dialog" :color="color" :icon="icon" :header="header" :width="width" tabindex="-1">
            <slot></slot>
        </bn-info>
    </div>
    <!-- Everything else -->
    <div v-else :data-cy="$attrs['data-cy']" :class="$attrs['class']">
        <!-- rendered indicators -->
        <v-alert v-if="type === 'bar'" v-bind="$attrs" :color="color" density="compact" :tile="$attrs.tile === '' || $vuetify.display.smAndDown" class="ma-0">
            <template #prepend>
                <font-awesome-icon :size="iconSize" :icon="['far', buttonIcon]" class="mr-3" />
            </template>
            <div v-if="message" v-html="message"></div>
            <template v-else>
                <slot></slot>
            </template>
        </v-alert>

        <v-alert v-else-if="type === 'outlined'" v-bind="$attrs" :color="color" density="compact" variant="outlined" class="mx-4 mt-4 mb-0">
            <template #prepend>
                <font-awesome-icon :size="iconSize" :icon="['far', buttonIcon]" class="mr-3" />
            </template>
            <div v-if="message" v-html="message"></div>
            <template v-else>
                <slot></slot>
            </template>
        </v-alert>

        <v-alert v-else-if="type === 'text'" v-bind="$attrs" :color="color" density="compact" variant="text" class="ma-0">
            <template #prepend>
                <font-awesome-icon :size="iconSize" :icon="['far', buttonIcon]" class="mr-3" />
            </template>
            <div v-if="message" v-html="message"></div>
            <template v-else>
                <slot></slot>
            </template>
        </v-alert>

        <v-btn v-else :size="size" v-bind="$attrs" :icon="!label" :variant="!!label ? 'text' : null" :color="color" @click.stop="isOpen = true">
            <font-awesome-icon :size="iconSize" :icon="['far', buttonIcon]" :class="{ 'mr-2': label }" />
            <span v-if="label" v-text="label" />
        </v-btn>

        <!-- Dialog/Snackbar popups -->
        <v-snackbar v-if="type === 'snackbar'" v-bind="$attrs" v-model="isOpen" color="info" bottom :timeout="-1" vertical>
            <div data-test="slot"><slot></slot></div>
            <v-btn class="closeBtn mt-4" variant="outlined" color="white" @click="isOpen = false">GOT IT</v-btn>
        </v-snackbar>

        <v-dialog v-else-if="type === 'dialog'" v-bind="$attrs" v-model="isOpen" scrollable :width="width" persistent>
            <v-card :tile="$vuetify.display.xs" class="d-flex flex-column">
                <v-toolbar density="compact" flat :color="color" class="flex-grow-0">
                    <font-awesome-icon size="lg" :icon="['far', headerIcon]" class="ml-3" />
                    <v-toolbar-title>{{ headerText }}</v-toolbar-title>
                    <v-btn v-if="$vuetify.display.xs" icon @click="isOpen = false">
                        <font-awesome-icon :icon="['far', 'times']" size="lg" />
                    </v-btn>
                    <v-btn v-else variant="outlined" @click="isOpen = false">
                        <font-awesome-icon :icon="['far', 'times']" size="lg" class="mr-2" />
                        Close
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <div class="scroll-on">
                    <div v-if="message" class="pa-4" v-html="message"></div>
                    <div v-else class="pa-4">
                        <slot></slot>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<style>
/* Fix the snackbar margin to account for the notch */

.v-snackbar__wrapper {
    margin-top: var(--ion-safe-area-top, 0) !important;
}
</style>
